import React, { Component } from "react";

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

import { withRouter } from '../with-router';
import AuthService from "../services/auth";

import VirtualKeyboard from "../components/VirtualKeyboard";

const required = value => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};

class Login extends Component {
    constructor(props) {
        super(props);
        this.handleLogin = this.handleLogin.bind(this);
        
        this.state = {
            page_ready: false,
            input_name: "",
            username: "",
            password: "",
            loading: false,
            message: ""
        };
    }

    componentDidMount(){
        var username = "", password = "";
        if(this.props.router.location.search){
            let search_params = this.props.router.location.search;
            let params = new URLSearchParams(search_params);
            if(params.has('user')) username = params.get("user");
            if(params.has('pass')) password = params.get("pass");

        }else{
            let local_user = localStorage.getItem("user");
            if(local_user != null) {
                local_user = JSON.parse(local_user);
                username = local_user.username;
                password = local_user.password;
            }
        }

        this.setState({
            page_ready: true,
            username: username,
            password: password
        }, () => {
            if(this.state.username != "" && this.state.password != ""){
                this.handleLogin(null);
            }
        })
    }

    focusInput(name){
        this.setState({
            input_name: name,
        })
    }

    onKeyboardInputChange = (input) => {
        switch(this.state.input_name){
            case "username":
                this.setState({
                    username: input
                });
                break;
            case "password":
                this.setState({
                    password: input
                });
                break;
        }
    }

    handleLogin(e) {
        if(e) e.preventDefault();

        this.setState({
            message: "",
            loading: true
        });

        this.form.validateAll();

        if (this.checkBtn.context._errors.length === 0) {
            AuthService.login(this.state.username, this.state.password).then(() => {
                let new_user = {"username": this.state.username, "password": this.state.password}
                localStorage.setItem("user", JSON.stringify(new_user));
                localStorage.removeItem("demo_user");

                this.props.router.navigate("/home");
                window.location.reload();
            },
            error => {
                localStorage.removeItem("user");
                const resMessage = (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                    error.message ||
                    error.toString();

                this.setState({
                    loading: false,
                    message: resMessage,
                });
            });
        } else {
            this.setState({
                loading: false
            });
        }
    }

    gotoDemo = (e) => {
        if(e) e.preventDefault();

        this.props.router.navigate("/guest/login");
        window.location.reload();
    }

    render() {
        return (
            <div className="col-md-12">
                <div className="card card-container">
                    <img
                        src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
                        alt="profile-img"
                        className="profile-img-card"
                    />

                    <Form
                        onSubmit={this.handleLogin}
                        ref={c => {
                            this.form = c;
                        }}
                    >
                        <div className="form-group">
                            <label htmlFor="username">Username</label>
                            <Input
                                type="text"
                                className="form-control"
                                name="username"
                                value={this.state.username}
                                readOnly={true}
                                onClick={e => this.focusInput("username")}
                                validations={[required]}
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="password">Password</label>
                            <Input
                                type="password"
                                className="form-control"
                                name="password"
                                value={this.state.password}
                                readOnly={true}
                                onClick={e => this.focusInput("password")}
                                validations={[required]}
                            />
                        </div>

                        <div className="form-group">
                            <div className="d-grid gap-2" style={{marginTop: "10px"}}>
                                <button className="btn btn-primary btn-block" disabled={this.state.loading}>
                                    {this.state.loading && (
                                        <span className="spinner-border spinner-border-sm"></span>
                                    )}
                                    <span>Login</span>
                                </button>
                            </div>
                        </div>

                        {this.state.message && (
                            <div className="form-group">
                            <div className="alert alert-danger" role="alert">
                                {this.state.message}
                            </div>
                            </div>
                        )}
                        <CheckButton
                            style={{ display: "none" }}
                                ref={c => {
                                this.checkBtn = c;
                            }}
                        />
                    </Form>
                </div>

                <div className="text-center">
                    <button className="btn" onClick={this.gotoDemo}>
                        <a href="#">Marketing Demo</a>
                    </button>
                </div>

                <VirtualKeyboard
                    inputName={this.state.input_name}
                    onChange={this.onKeyboardInputChange}
                />
            </div>
        );
    }
}

export default withRouter(Login);