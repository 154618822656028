import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import { Modal, Button }  from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFlag } from '@fortawesome/free-solid-svg-icons'

class ErrorModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: null,
            show: false,
            title: this.props.title,
            text: this.props.text
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot){
        if (this.props.show !== prevState.show) {
            this.setState({
                show: this.props.show,
                title: this.props.title,
                text: this.props.text
            }, () => {
                this.addjustLinebreak();
            })
        }
    }

    addjustLinebreak(){
        let class_name = ".error-modal .text";
        let dom = document.querySelector(class_name)
        if(dom){
            dom.innerHTML = "";
            let new_text = this.state.text
            new_text = new_text.split(/(?:\r\n|\r|\n)/g);
            for(var i=0; i<new_text.length; i++){
                if(new_text[i]) {
                    let p = document.createElement("li");
                    p.textContent = new_text[i];
                    dom.appendChild(p);
                }
            }
        }
    }

    hideModal(){
        this.setState({
            show: false
        })
    }

    goHome(){
        this.setState({ redirect: { path: localStorage.getItem("demo_user") ? "/guest/home" : '/home', state: null } });
    }

    render() {
        if (this.state.redirect) {
            return <Navigate to={this.state.redirect.path} state={this.state.redirec} />
        }
        return (
            <Modal className="error-modal" show={this.state.show} animation={true} centered>
                <Modal.Header>
                    <Modal.Title className="w-100 text-center">
                        <div><FontAwesomeIcon icon={faFlag} size="1x" color="#dc3545" /> {this.state.title}</div>    
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="pb-0">
                    <div className="container">
                        <div className="row">
                            <div className="col text-center">
                                <img src="/assets/images/refree2.png" width={"200px"}/>
                            </div>
                            <div className="col text-start d-flex align-items-center">
                                <ul className="text"></ul>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="container m-0 p-0">
                        <div className="row">
                            <div className="col d-grid"><Button variant="primary" onClick={e => this.goHome()}>Return to Lobby</Button></div>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        )
    }
}
export default ErrorModal;