
import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import htmlParse from 'html-react-parser';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFlag, faHandPointer, faHandPointRight } from '@fortawesome/free-solid-svg-icons'

import moment from 'moment';

import { withRouter } from '../../../with-router';
import GameService from "../../../services/game";
import FirebaseService from "../../../services/firebase";

import GameTimeout from "../../../components/GameTimeout";
import TextModal from "../../../components/TextModal";
import ErrorModal from "../../../components/ErrorModal";

import './PickTheProps.css';

class PickTheProps extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page_ready: false,
            redirect: null,
            venue: null,
            promo: null,
            user_picks: [],
            player: null,
            ticket: null,
            total_picks: 0,
            success_modal: false,
            help_modal: false,
            how_to_play_modal: false,
            submit_modal: false,
            pick_error_modal: false,
            error_modal: {show: false, title: "", text: ""},
            ticket_modal: false,
            quick_pick: false,
            block: false,
            tutorial: false,
            timeout: 60000
        };
        this.current_time = moment();
    }

    componentDidMount() {
        if(this.props.router.location.state == null){
            this.setState({ redirect: {path: localStorage.getItem("demo_user") ? "/guest/home" : '/home', state: null} })
            return;
        }

        this.props.showModal("Loading...");
        let venue = this.props.router.location.state.venue;
        let promo = this.props.router.location.state.promo;
        
        if(promo.league.pickscreen_background_image_url) this.backgroundImage(promo.league.pickscreen_background_image_url);
        else if(venue.pickscreen_background_image_url) this.backgroundImage(venue.pickscreen_background_image_url);
        
        let player_info = this.props.router.location.state.player;
        let played = -1;
        if(player_info.ids_of_promos_played != null && player_info.ids_of_promos_played.length > 0) played = player_info.ids_of_promos_played.findIndex(a => a == promo.id);
        
        FirebaseService.logEvent("play_promo_start", {"venue_id": venue.id, "card_num": player_info.card_number, "promo_id": promo.id})
        GameService.getPromo(promo.id).then((data) => {
            if(data.promos[0].promo_periods.length == 0){
                this.setState({
                    page_ready: false,
                    success_modal: false,
                    help_modal: false,
                    how_to_play_modal: false,
                    submit_modal: false,
                    pick_error_modal: false,
                    error_modal: {show: true, title: "Something went wrong!", text: "There are no events scheduled for today in this promotion.\nPlease return to the Lobby."},
                    ticket_modal: false,
                }, () => {
                    this.props.hideModal();
                })
            }else{
                let total_picks = 0;
                data.promos[0].promo_periods.forEach((a, index) => {
                    total_picks += a.events.length;
                    
                    a.events.forEach(b => {
                        b.event_choices.forEach(c => {
                            if(c.odds) c.ODDS = parseInt(c.odds)
                        })
                    })
                })

                var new_promo_events = JSON.parse(JSON.stringify(data.promos[0].promo_periods[0].events));
                var sorted_new_promo_events = new_promo_events.sort((a, b) => {return moment(a.starts_at).diff(moment(b.starts_at))})
                data.promos[0].promo_periods[0].events = sorted_new_promo_events;

                this.timeout_interval = null;
                this.setState({
                    page_ready: true,
                    total_picks: total_picks,
                    venue: venue,
                    player: player_info,
                    promo: data.promos[0],
                    success_modal: false,
                    help_modal: false,
                    how_to_play_modal: played > -1 ? false : true,
                    submit_modal: false,
                    pick_error_modal: false,
                    error_modal: {show: false, title: "", text: ""},
                    ticket_modal: false,
                    tutorial: played > -1 ? false : true,
                }, () => {
                    //window.history.replaceState({}, document.title)
                    this.calcHeight();
                    this.props.hideModal();
                })
            }
        }, error => {
            console.log(error)
            this.setState({ redirect: {path: localStorage.getItem("demo_user") ? "/guest/home" : '/home', state: null} })
        });
    }

    componentWillUnmount(){
        if(this.timeout_interval) clearInterval(this.timeout_interval);
    }

    backgroundImage(bg){
        let html = document.getElementsByTagName("html");
        if(html.length > 0){
            html[0].style.backgroundImage = "url('" + bg + "')";
        }
    }

    userPick(index, position, reloop){
        let promo = this.state.promo;
        let user_picks = JSON.parse(JSON.stringify(this.state.user_picks));
        let current_pick = JSON.parse(JSON.stringify(promo.promo_periods[0].events[index].event_choices[position].choice));
        
        current_pick["EVENT_NAME"] = promo.promo_periods[0].events[index].name;
        current_pick["ODDS"] = promo.promo_periods[0].events[index].event_choices[position].ODDS;
        current_pick["INDEX"] = index;
        current_pick["EVENT_ID"] = promo.promo_periods[0].events[index].id;
        current_pick["EVENT_CHOICE_ID"] = promo.promo_periods[0].events[index].event_choices[position].id;
        
        if(user_picks.length > 0){
            let existing_index = user_picks.findIndex(a => a.INDEX == current_pick.INDEX);
            if(existing_index == -1){
                user_picks.push(current_pick);
            }else{
                user_picks[existing_index] = current_pick;
            }
            user_picks.sort(function(a, b){return a.INDEX - b.INDEX});
        }else{
            user_picks.push(current_pick);
        }

        var new_credits = this.calcCredits(user_picks)
        if(new_credits < 0 && this.state.quick_pick == false){
            this.togglePickErrorModal(true);

        }else if(new_credits < 0 && this.state.quick_pick == true){
            if(reloop){
                this.setState({ quick_pick: false, block: false }, () => {
                    this.togglePickErrorModal(true);
                })
            }else{
                let new_position = position == 1 ? 0 : 1;
                this.userPick(index, new_position, true);
            }

        }else{
            this.setState({
                user_picks: user_picks,
                success_modal: false,
                help_modal: false,
                how_to_play_modal: false,
                submit_modal: false,
                pick_error_modal: false,
                ticket_modal: false,
                tutorial: false,
            }, () => {
                let new_index = 0
                user_picks.forEach(a => { 
                    if(a.INDEX - new_index == 0) new_index = a.INDEX + 1;
                })
                let picks_element = document.querySelector(`#picks-${index}`);
                let choices_element = document.querySelector(`#event-${new_index}`);
                    
                if(this.state.total_picks == this.state.user_picks.length){
                    if(picks_element) picks_element.scrollIntoView();

                    if(this.state.quick_pick){
                        this.setState({
                            quick_pick: false, block: false, submit_modal: true
                        })
                    }else{
                        this.setState({
                            quick_pick: false, block: false
                        })
                    }
                }else{
                    if(picks_element) picks_element.scrollIntoView();
                    if(choices_element) choices_element.scrollIntoView({behavior: "smooth"});

                    if(this.state.quick_pick) {
                        setTimeout(() => { 
                            this.quickPick();
                        }, 500);
                    }
                }
            })
        }
    }

    quickPick(){
        this.setState({
            quick_pick: true,
            block: true,
            success_modal: false,
            help_modal: false,
            how_to_play_modal: false,
            submit_modal: false,
            pick_error_modal: false,
            ticket_modal: false
        }, () => {
            let promo = this.state.promo;
            let user_picks = JSON.parse(JSON.stringify(this.state.user_picks));
            
            let new_index = 0
            user_picks.forEach(a => { 
                if(a.INDEX - new_index == 0) new_index = a.INDEX + 1;
            })

            if(new_index < promo.promo_periods[0].events.length){
                let total_choices = promo.promo_periods[0].events[new_index].event_choices.length
                let new_position = Math.floor(Math.random() * total_choices);
                
                let choices_element = null;
                choices_element = document.querySelector(`#event-${new_index} .event-choice-${new_position}`);
                if(choices_element){
                    choices_element.click();
                }else{
                    this.quickPick();
                }

            }else{
                this.setState({ quick_pick: false, block: false })
            }
        })
    }

    prepUserPicks(){
        this.setState({
            success_modal: false,
            help_modal: false,
            how_to_play_modal: false,
            submit_modal: false,
            pick_error_modal: false,
            ticket_modal: false
        }, () => {
            this.submitUserPicks();
        })
    }

    submitUserPicks(){
        let new_current_time = moment();
        var duration = moment.duration(new_current_time.diff(this.current_time));

        let user_picks = JSON.parse(JSON.stringify(this.state.user_picks))
        let eventchoiceidslist = [];
        user_picks.forEach(a => {
            eventchoiceidslist.push({"eventid": a.EVENT_ID, "eventchoiceid": a.EVENT_CHOICE_ID, "choiceid": a.id, })
        })

        let playerid = this.state.player.id;
        let promoid = this.state.promo.id;
        let promoperiodid = this.state.promo.promo_periods[0].id
        let playedatlocal = moment().format();

        this.props.showModal("Loading...");
        FirebaseService.logEvent("play_promo_done", {"venue_id": this.state.venue.id, "card_num": this.state.player.card_number, "promo_id": this.state.promo.id, "time_spent": duration.asMinutes()})
        GameService.postTicketWOTiebreaker(playerid, promoid, promoperiodid, eventchoiceidslist, playedatlocal).then((data) => {
            this.current_time = moment();
            FirebaseService.logEvent("play_promo_success", {"venue_id": this.state.venue.id, "card_num": this.state.player.card_number, "promo_id": this.state.promo.id, "ticket_id": data.ticket.id})
            
            this.props.hideModal();
            this.setState({
                ticket: data.ticket,
                success_modal: true
            }, () => {
                this.startSuccessModalTimer()
            })
        }, error => {
            console.log(error)
        });
    }

    onSuccessScreen(){
        if(this.state.success_modal && !localStorage.getItem("demo_user")) {
            setTimeout(() => {
                window.print();
            },500)
        }
    }

    startSuccessModalTimer(){
        let seconds_dom = document.querySelector('.success-modal .seconds');
        var timeout = 15;
        if(seconds_dom) seconds_dom.innerHTML = timeout;
        this.timeout_interval = setInterval(() => {
            if(timeout > 0){
                timeout = timeout - 1;
                if(seconds_dom) seconds_dom.innerHTML = timeout;
            }else if(timeout == 0){
                let new_current_time = moment();
                var duration = moment.duration(new_current_time.diff(this.current_time));
                FirebaseService.logEvent("play_promo_success_exit", {"venue_id": this.state.venue.id, "card_num": this.state.player.card_number, "promo_id": this.state.promo.id, "time_spent":  duration.asMinutes(), "manual_press": false})
                
                if(this.timeout_interval) clearInterval(this.timeout_interval);
                this.setState({ redirect: {path: localStorage.getItem("demo_user") ? "/guest/home" : '/home', state: null} })
            }
        }, 1000)
    }

    clearUserPicks(e){
        e.preventDefault();
        this.setState({
            user_picks: [],
            success_modal: false,
            help_modal: false,
            how_to_play_modal: false,
            submit_modal: false,
            pick_error_modal: false,
            ticket_modal: false
        }, () => {
            let choices_element = document.querySelector('#event-0');
            if(choices_element) choices_element.scrollIntoView({behavior: "smooth"});
        })
    }

    toggleHelpModal(new_show){
        this.setState({
            success_modal: new_show ? false : this.state.success_modal,
            help_modal: new_show,
            how_to_play_modal: new_show ? false : this.state.how_to_play_modal,
            submit_modal: new_show ? false : this.state.submit_modal,
            pick_error_modal: new_show ? false : this.state.pick_error_modal,
            ticket_modal: new_show ? false : this.state.ticket_modal,
        })
    }

    toggleSubmitModal(new_show){
        this.setState({
            success_modal: new_show ? false : this.state.success_modal,
            help_modal: new_show ? false : this.state.help_modal,
            how_to_play_modal: new_show ? false : this.state.how_to_play_modal,
            submit_modal: new_show,
            pick_error_modal: new_show ? false : this.state.pick_error_modal,
            ticket_modal: new_show ? false : this.state.ticket_modal,
        })
    }

    togglePickErrorModal(new_show){
        this.setState({
            success_modal: new_show ? false : this.state.success_modal,
            help_modal: new_show ? false : this.state.help_modal,
            how_to_play_modal: new_show ? false : this.state.how_to_play_modal,
            submit_modal: new_show ? false : this.state.submit_modal,
            pick_error_modal: new_show,
            ticket_modal: new_show ? false : this.state.ticket_modal,
        })
    }

    toggleTicketModal(new_show){
        this.setState({
            success_modal: new_show ? false : this.state.success_modal,
            help_modal: new_show ? false : this.state.help_modal,
            how_to_play_modal: new_show ? false : this.state.how_to_play_modal,
            submit_modal: new_show ? false : this.state.submit_modal,
            pick_error_modal: new_show ?  false : this.state.pick_error_modal,
            ticket_modal: new_show,
        })
    }

    determineUserPicksUI(index, choice){
        let user_picks = this.state.user_picks;
        if(choice){
            let existing_index = user_picks.findIndex(a => a.INDEX == index && a.id == choice.id);
            if(existing_index > -1){
                return "selected";
            }
        }else{
            let existing_index = user_picks.findIndex(a => a.INDEX == index);
            if(existing_index > -1){
                return "selected_2";
            }
        }
        return "";
    }

    calcHeight(){
        let top_bar = document.querySelector('.top-bar');
        let margin_offset_height = 16;
        
        let total_offset_height = top_bar.offsetHeight + (margin_offset_height*1); 
        let main_container = document.querySelector('.scroll-container');
        if(main_container){
            main_container.style.height = "calc(100vh - " + total_offset_height + "px)";
        }

        let total_offset_height2 = top_bar.offsetHeight + margin_offset_height; 
        let ticket_container = document.querySelector('.ticket-container');
        if(ticket_container){
            ticket_container.style.height = "calc(100vh - " + total_offset_height2 + "px)";
        }

        let btn_submit = document.querySelector('.btn-submit');
        let btn_clear = document.querySelector('.btn-clear');
        let user_picks_container_title = document.querySelector('.user-picks-container .title')
        
        let total_offset_height3 = top_bar.offsetHeight + btn_submit.offsetHeight + btn_clear.offsetHeight + user_picks_container_title.offsetHeight + margin_offset_height; 
        let user_picks_scroll_container = document.querySelector('.user-picks-scroll-container');
        if(user_picks_scroll_container){
            user_picks_scroll_container.style.maxHeight = "calc(100vh - " + (total_offset_height3 + 15) + "px)";
        }

        //tutorial width
        let btn_tutorial = document.querySelector('.btn-tutorial');
        let tutorial_padding = document.querySelector('.tutorial-padding');
        if(btn_tutorial){
            btn_tutorial.style.width = ((main_container.offsetWidth-15) + "px");
        }
        if(tutorial_padding){
            tutorial_padding.style.height = (btn_tutorial.offsetHeight + 10 + "px");
        }
    }

    onTimeout(){
        this.setState({
            success_modal: false,
            help_modal: false,
            how_to_play_modal: false,
            submit_modal: false,
            pick_error_modal: false,
            ticket_modal: false
        })
    }

    onToggleTextModal(new_state){
        this.setState({ timeout: new_state ? 60000*3 : 60000 })
    }

    testJSON(text) {
        if (typeof text !== "string") {
            return false;
        }
        try {
            JSON.parse(text);
            return true;
        } catch (error) {
            return false;
        }
    }

    calcCredits(new_user_picks){
        let initial_points_credit = this.state.promo.promo_periods[0].initial_points_credit
        let user_picks = new_user_picks ? new_user_picks : this.state.user_picks;

        let total_wager = 0
        user_picks.forEach(a => { 
            total_wager += a.ODDS && a.ODDS > 0 ? 100 : Math.abs(a.ODDS)
        })

        //100 + Math.abs(a.ODDS)
        //{inner_item.ODDS && inner_item.ODDS > 0 ? "100" : -(inner_item.ODDS)}

        return parseInt(initial_points_credit) - total_wager
    }

    goHome(){
        this.setState({ redirect: { path: localStorage.getItem("demo_user") ? "/guest/home" : '/home', state: null } });
    }

    render() {
        if (this.state.redirect) {
            return <Navigate to={this.state.redirect.path} state={this.state.redirect.state}/>
        }

        return (
            <div id="pick-the-props" className="container-fluid">
                <ErrorModal show={this.state.error_modal.show} title={this.state.error_modal.title} text={this.state.error_modal.text}/>
                { this.state.block ? <div className="invincible-wall"></div> : null }
                {(this.state.page_ready) ?
                <div>
                    <div className="receipt">
                        <div className="text-center">
                            <img src={this.state.venue.logo_url} width="100px"/>
                        </div>
                        <div className="pt-2 text-center mb-2">
                            <div className="fw-bold fs-5">
                                {this.state.venue.name}<br />
                                {this.state.promo.name}<br />
                                {this.state.promo.promo_periods.length > 0 && this.state.promo.promo_periods[this.state.promo.promo_periods.length-1].name}
                            </div>
                            {this.state.ticket && this.state.ticket.played_at_local_as_string &&
                                <div className="text-center mb-2">
                                    {moment(this.state.ticket.played_at_local_as_string).format("MMM D, YYYY h:mm a")}
                                </div>
                            }
                            <ul className="p-0 m-0 text-start" style={{listStyle: "none", }}>
                                {this.state.user_picks.length > 0 && this.state.user_picks.map((item, index) => ( 
                                    <li className="pick" key={index}>
                                        <div style={{lineHeight: "1.2em"}}>{item.EVENT_NAME}</div>
                                        <div className={index < this.state.user_picks.length ? "pb-2" : ""}>{item.name} {item.ODDS && item.ODDS > 0 && "+"}{item.ODDS && item.ODDS}</div>
                                    </li>
                                ))}
                                <li>Points Credit: {this.calcCredits()}</li>
                            </ul>
                        </div>
                        {this.state.promo.receipt_disclaimer != "" &&
                            <div>
                                <hr className="my-1" />
                                <div className="pt-2 disclaimer">
                                    {htmlParse(this.state.promo.receipt_disclaimer)}
                                </div>
                            </div>
                        }
                        {this.state.promo.receipt_message != "" &&
                            <div>
                                <hr className="my-1" />
                                <div className="pt-2 disclaimer">
                                    {htmlParse(this.state.venue.receipt_message)}
                                </div>
                            </div>
                        }
                    </div>
                    <div className="main-container">
                        {!this.state.success_modal ? <GameTimeout {...this} timeout={this.state.timeout} onTimeout={() => this.onTimeout()}/> : null}
                        <TextModal onToggle={(new_state) => this.onToggleTextModal(new_state)} title="Help" class="help_modal" help_modal={this.state.help_modal} text={this.state.promo.help_rules}/>
                        <TextModal onToggle={(new_state) => this.onToggleTextModal(new_state)} title="How To Play" class="how_to_play_modal" help_modal={this.state.how_to_play_modal} text={this.state.promo.how_to_play}/>
                        <Modal onShow={this.onSuccessScreen()} className="success-modal" show={this.state.success_modal} animation={true} centered size="sm">
                            <Modal.Header>
                                <Modal.Title className="w-100">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col p-0 d-flex align-items-center">
                                                Success!
                                            </div>
                                            <div className="col text-end p-0">
                                                <Button onClick={e => {
                                                        let new_current_time = moment();
                                                        var duration = moment.duration(new_current_time.diff(this.current_time));
                                                        FirebaseService.logEvent("play_promo_success_exit", {"venue_id": this.state.venue.id, "card_num": this.state.player.card_number, "promo_id": this.state.promo.id, "time_spent":  duration.asMinutes(), "manual_press": true})
                                                        
                                                        this.goHome();
                                                    }}
                                                >
                                                    Home <span className="seconds"></span>
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="text-center">
                                    <img src={this.state.venue.logo_url} width="100px"/>
                                </div>
                                <div className="pt-2 text-center mb-2">
                                    <div className="fw-bold fs-5">
                                        {this.state.venue.name}<br />
                                        {this.state.promo.name}<br />
                                        {this.state.promo.promo_periods.length > 0 && this.state.promo.promo_periods[this.state.promo.promo_periods.length-1].name}
                                    </div>
                                    {this.state.ticket && this.state.ticket.played_at_local_as_string &&
                                        <div className="text-center mb-2">
                                            {moment(this.state.ticket.played_at_local_as_string).format("MMM D, YYYY h:mm a")}
                                        </div>
                                    }
                                    <ul className="p-0 m-0 text-start" style={{listStyle: "none", }}>
                                        {this.state.user_picks.length > 0 && this.state.user_picks.map((item, index) => ( 
                                            <li className="pick pb-2" key={index}>
                                                <div style={{lineHeight: "1.2em"}}>{item.EVENT_NAME}</div>
                                                <div>{item.name} {item.ODDS && item.ODDS > 0 && "+"}{item.ODDS && item.ODDS}</div>
                                            </li>
                                        ))}
                                        <li>Points Credit: {this.calcCredits()}</li>
                                    </ul>
                                </div>
                                {this.state.promo.receipt_disclaimer != "" &&
                                    <div>
                                        <hr className="my-1" />
                                        <div className="pt-2 disclaimer">
                                            {htmlParse(this.state.promo.receipt_disclaimer)}
                                        </div>
                                    </div>
                                }
                                {this.state.promo.receipt_message != "" &&
                                    <div>
                                        <hr className="my-1" />
                                        <div className="pt-2 disclaimer">
                                            {htmlParse(this.state.venue.receipt_message)}
                                        </div>
                                    </div>
                                }
                            </Modal.Body>
                        </Modal>
                        <Modal className="ticket-modal" onHide={e => this.toggleTicketModal(false)} show={this.state.ticket_modal} animation={true} centered size="sm">
                            <Modal.Header closeButton>
                                <Modal.Title className="w-100">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col p-0 d-flex align-items-center">
                                                Ticket
                                            </div>
                                        </div>
                                    </div>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="text-center mb-2">
                                    <ul className="p-0 m-0 text-start" style={{listStyle: "none", }}>
                                        {this.state.user_picks.length > 0 && this.state.user_picks.map((item, index) => ( 
                                            <li className="pick pb-2" key={index}>
                                                <div style={{lineHeight: "1.2em"}}>{item.EVENT_NAME}</div>
                                                <div>{item.name} {item.ODDS && item.ODDS > 0 && "+"}{item.ODDS && item.ODDS}</div>
                                            </li>
                                        ))}
                                        <li>Points Credit: {this.calcCredits()}</li>
                                    </ul>
                                </div>
                            </Modal.Body>
                        </Modal>
                        <Modal className="pick-error-modal" show={this.state.pick_error_modal} animation={true} centered size="md">
                            <Modal.Header>
                                    <Modal.Title className="w-100">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col p-0 text-center">
                                                    <FontAwesomeIcon icon={faFlag} size="1x" color="#ffc107" /> Insufficient Credits
                                                </div>
                                            </div>
                                        </div>
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body className="pb-0">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col">
                                                <img src="/assets/images/refree-yellow-card.png" width={"200px"}/>
                                            </div>
                                            <div className="col text-start" style={{display: "grid", justifyContent: "center"}}>
                                                <div className="pb-2">You do not have enough point credits remaining for this choice.</div>
                                                <div className="pb-2">Please make other choices or tap Clear All to begin again.</div>
                                            </div>
                                        </div>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <div className="container m-0 p-0">
                                        <div className="row">
                                            <div className="col d-grid">
                                                <Button variant="secondary" onClick={(e) => this.clearUserPicks(e)}>Clear All</Button>
                                            </div>
                                            <div className="col d-grid">
                                                <Button variant="primary" onClick={(e) => this.togglePickErrorModal(false)}>Edit Picks</Button>
                                            </div>
                                        </div>
                                    </div>
                                </Modal.Footer>   
                        </Modal>
                        <Modal className="submit-modal" show={this.state.submit_modal} animation={true} centered size="md">
                            <Modal.Header>
                                    <Modal.Title className="w-100">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col p-0 text-center">
                                                    Next Ticket
                                                </div>
                                            </div>
                                        </div>
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col text-center">Are you sure you would like to submit this ticket?</div>
                                        </div>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <div className="container m-0 p-0">
                                        <div className="row">
                                            <div className="col d-grid">
                                                <Button variant="primary" onClick={(e) => this.toggleSubmitModal(false)}>Edit Picks</Button>
                                            </div>
                                            <div className="col d-grid">
                                                <Button variant="success" onClick={(e) => this.prepUserPicks()}>Enter Ticket</Button>
                                            </div>
                                        </div>
                                    </div>
                                </Modal.Footer>   
                        </Modal>
                        <div className="row p-2 top-bar" style={{backgroundColor: "lightgray"}}>
                            <div className="col-auto text-start d-flex align-items-center">
                                <Button onClick={e => {
                                        let new_current_time = moment();
                                        var duration = moment.duration(new_current_time.diff(this.current_time));
                                        FirebaseService.logEvent("play_promo_exit", {"venue_id": this.state.venue.id, "card_num": this.state.player.card_number, "promo_id": this.state.promo.id, "time_spent":  duration.asMinutes(), "manual_press": true})
                                        
                                        this.goHome();
                                    }}
                                    variant="secondary" className="me-2" 
                                >
                                    Home
                                </Button>
                                <Button variant="secondary" onClick={e => this.toggleHelpModal(true)}>
                                    Help
                                </Button>
                            </div>
                            <div className="col text-center header-title">
                                {this.state.promo.name} 
                            </div>
                            <div className="col-auto text-end">
                                {/*<Button variant="secondary" className="me-2">
                                    Official Rules
                                </Button>
                                <Button variant="secondary">
                                    History
                                </Button>*/}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <div className="container mt-2">
                                    <div className="row">
                                        <div className="col-9 choice-parent-container">
                                            <div>
                                                <div className="row scroll-container" style={{borderRadius: "15px"}}>
                                                    <div className="col px-0">
                                                        <div className="p-0">
                                                        {this.state.promo.promo_periods[0] && this.state.promo.promo_periods[0].events.map((item, index) => ( 
                                                                <div key={"event-" + index} id={"event-" + index} 
                                                                    className={index > 0 ? "mt-5 pt-2 pb-0 px-2 text-center" : "pt-2 pb-0 px-2 text-center"} 
                                                                    style={{backgroundColor: "whitesmoke", borderRadius: "15px", overflow: "hidden"}}
                                                                >
                                                                    <div className="row pb-1 fs-5 fw-bold"
                                                                        style={{backgroundColor: "whitesmoke", position: "relative", "zIndex": "1"}}
                                                                    >
                                                                        {item.name && 
                                                                            <div className="col text-start">
                                                                                {item.name}
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                    <div className="row" style={{backgroundColor: "white"}}>
                                                                        <div className="col ps-0 pe-2 m-0 text-start d-flex">
                                                                            <div className={"d-flex me-2 align-items-end justify-content-start " + this.determineUserPicksUI(index)}>
                                                                                <img src={item.image_url} className="img m-0 p-0"/>
                                                                            </div>
                                                                            <div className="ps-3 description d-flex align-items-center" style={{position: "relative", zIndex: "1"}}>
                                                                                {item.description}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-4 p-0 m-0">
                                                                            {item.event_choices.map((inner_item, inner_index) => (
                                                                                inner_item.choice && 
                                                                                    <div key={"event-choice-" + inner_index} style={{height: "50%"}}
                                                                                        className={"position-relative d-flex flex-column align-items-center justify-content-center label item-pick p-3 event-choice-" + inner_index + " " + this.determineUserPicksUI(index, inner_item.choice)}
                                                                                        onClick={e => this.userPick(index, inner_index)}
                                                                                    >
                                                                                        <div>
                                                                                            <div className="row title m-0 p-0">
                                                                                                <div className="col text-center m-0 px-0">{inner_item.choice.name}&nbsp;{inner_item.ODDS && inner_item.ODDS > 0 && "+"}{inner_item.ODDS && inner_item.ODDS}</div>
                                                                                            </div>
                                                                                            <div className="row text m-0 p-0">
                                                                                                <div className="col m-0 ps-0 pe-1 d-flex align-items-center justify-content-end">
                                                                                                    Wager
                                                                                                </div>
                                                                                                <div className="col m-0 ps-1 pe-0 d-flex align-items-center justify-content-start">
                                                                                                    {inner_item.ODDS && inner_item.ODDS > 0 ? "100" : -(inner_item.ODDS)} <span className="fs-7">&nbsp;points</span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="row text m-0 p-0">
                                                                                                <div className="col m-0 ps-0 pe-1 d-flex align-items-center justify-content-end">
                                                                                                    Win
                                                                                                </div>
                                                                                                <div className="col m-0 ps-1 pe-0 d-flex align-items-center justify-content-start">
                                                                                                    {inner_item.ODDS && inner_item.ODDS > 0 ? 100 + inner_item.ODDS : 100 + -(inner_item.ODDS)} <span className="fs-7">&nbsp;points</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        {this.state.tutorial && index == 0 && inner_index == 0 &&
                                                                                            <div className="position-absolute text-end" style={{top: "calc(55%)", zIndex: 1}}><img src="/assets/images/pointer.gif" width={"50%"}/></div>
                                                                                        }
                                                                                    </div>
                                                                            ))}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                        {this.state.tutorial &&
                                                            <div>
                                                                <Button className="btn-tutorial m-2 position-fixed" style={{zIndex: 2, bottom: 0, filter: "drop-shadow(-5px 5px 4px rgba(0,0,0,0.5))"}}>
                                                                    <div className="container">
                                                                        <div className="row">
                                                                            <div className="col fs-3">
                                                                                <FontAwesomeIcon icon={faHandPointer} size="1x" />&nbsp;&nbsp;<strong>Let's get started</strong>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col fs-5">
                                                                                Choose your answer to each question
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Button>
                                                            </div>
                                                        }
                                                        {this.state.tutorial &&
                                                            <div className="tutorial-padding"></div>
                                                        }
                                                        <div className="mobile-padding"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col ticket-parent-container">
                                            <div className="container ticket-container pt-0" style={{backgroundColor: "rgba(0, 0, 0, 0.5)"}}>
                                                <div className="row px-2 pt-2 btn-submit">
                                                    <div className="col text-center d-grid p-0">
                                                        <Button variant="secondary" onClick={e => this.clearUserPicks(e)} disabled={this.state.user_picks.length > 0 ? false : true}>
                                                            Clear All
                                                        </Button>
                                                    </div>
                                                </div>

                                                <div className="row px-2 user-picks-container">
                                                    <div className="pt-3 px-0 col text-start title">
                                                        <div className="px-2" style={{backgroundColor: "gray", color: "white"}}>My Ticket</div>
                                                    </div>
                                                    <ul className="m-0 p-0 user-picks-scroll-container">
                                                        {this.state.user_picks.length == 0 &&
                                                            <li>Please make a selection</li>
                                                        }
                                                        {this.state.user_picks.length > 0 &&
                                                            <li className="px-2 fw-bold" style={{backgroundColor: "silver"}}>{this.state.promo.promo_periods[0].name}</li>
                                                        }   
                                                        {this.state.user_picks.length > 0 && this.state.user_picks.map((item, index) => ( 
                                                            <li key={index} id={"picks-" + index} className="pt-2">
                                                                <div style={{lineHeight: "1.2em"}}>{item.EVENT_NAME}</div>
                                                                <div>{item.name} {item.ODDS && item.ODDS > 0 && "+"}{item.ODDS && item.ODDS}</div>
                                                            </li>
                                                        ))}
                                                        <li className="point-credits mb-3 px-2 fw-bold" style={{backgroundColor: "silver"}}>Points Credit: {this.calcCredits()}</li>
                                                    </ul>
                                                </div>

                                                <div className="row px-2 pb-2 btn-clear">
                                                    <div className="col text-center d-grid p-0">
                                                        {this.state.user_picks.length != this.state.total_picks &&
                                                            <Button variant="success" onClick={e => this.quickPick()}>
                                                                Quick Pick
                                                            </Button>
                                                        }
                                                        {this.state.user_picks.length == this.state.total_picks &&
                                                            <div className="position-relative">
                                                                <div className="d-grid">
                                                                    <Button className="btn-custom-submit" variant="success" onClick={() => this.toggleSubmitModal(true)}>
                                                                        Submit
                                                                    </Button>
                                                                </div>
                                                                <div className="position-absolute" style={{top: "0px", zIndex: 1}}>
                                                                    <Button className="m-2 position-absolute" style={{width: "600px", right: "0px", top: "-33px", filter: "drop-shadow(-5px 5px 4px rgba(0,0,0,0.5))"}}>
                                                                        <div className="container">
                                                                            <div className="row">
                                                                                <div className="col fs-3">
                                                                                    <strong>Great Picks - Your ticket is full&nbsp;&nbsp;<FontAwesomeIcon icon={faHandPointRight} size="1x" /></strong>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col fs-5">
                                                                                    Tap the Submit button when you are done
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Button>
                                                                </div>
                                                                <div className="position-absolute text-end" style={{top: "0px", zIndex: 1, pointerEvents: "none"}}><img src="/assets/images/pointer.gif" width={"30%"}/></div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mobile-ticket-parent-container" style={{backgroundColor: "lightgray"}}>
                            <div className="p-2">
                                <div className="row">
                                    <div className="col text-end">
                                        <Button variant="secondary" onClick={e => this.clearUserPicks(e)} disabled={this.state.user_picks.length > 0 ? false : true}>
                                            Clear All
                                        </Button>
                                    </div>
                                    <div className="col-auto text-center">
                                        <Button variant="secondary" onClick={e => this.toggleTicketModal(true)} disabled={this.state.user_picks.length > 0 ? false : true}>
                                            Ticket
                                        </Button>
                                    </div>
                                    <div className="col text-start">
                                        {this.state.user_picks.length != this.state.total_picks &&
                                            <Button variant="success" onClick={e => this.quickPick()}>
                                                Quick Pick
                                            </Button>
                                        }
                                        {this.state.user_picks.length == this.state.total_picks &&
                                            <Button className="btn-custom-submit" variant="success" onClick={() => this.toggleSubmitModal(true)}>
                                                Submit
                                            </Button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> : null
                }
            </div>
        );
    }
}

export default withRouter(PickTheProps);