import React, { Component } from "react";
import { Modal, Button }  from 'react-bootstrap';

class HelpModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            text: this.props.text,
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot){
        if (this.props.help_modal !== prevState.show) {
            this.setState({
                show: this.props.help_modal
            }, () => {
                if(this.state.show){
                    this.addjustLinebreak();
                    this.props.onToggle(true);
                }
            })
        }
    }

    addjustLinebreak(){
        let class_name = "." + this.props.class + " .modal-body-text";
        let dom = document.querySelector(class_name)
        if(dom){
            dom.innerHTML = "";
            let new_text = this.state.text
            new_text = new_text.split(/(?:\r\n|\r|\n)/g);
            for(var i=0; i<new_text.length; i++){
                if(new_text[i]) {
                    let p = document.createElement("li");
                    p.textContent = new_text[i];
                    dom.appendChild(p);
                }
            }
        }
    }

    toggleModal(new_state){
        this.props.onToggle(new_state);
        this.setState({
            show: new_state
        })
    }

    render() {
        return (
            <Modal className={this.props.class} show={this.state.show} animation={true} centered onHide={() => {this.toggleModal(false)}}>
                <Modal.Header>
                    <Modal.Title className="w-100 text-center">
                        <div>{this.props.title}</div>    
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <div className="row">
                            <ul className="col modal-body-text mb-1"></ul>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="container m-0 p-0">
                        <div className="row">
                            <div className="col d-grid">
                                <Button variant="primary" onClick={(e) => this.toggleModal(false)}>Continue</Button>
                            </div>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        )
    }
}
export default HelpModal;