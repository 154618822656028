import React, { Component } from "react";
import { Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./App.css";

import { Modal }  from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

import PrivateRoute from "./components/PrivateRoute";

import ErrorBoundary from "./ErrorBoundary";

import GuestLogin from "./pages/GuestLogin";
import Login from "./pages/Login";
import Logout from "./pages/Logout";

import GuestHome from "./pages/home/GuestHome";
import Home from "./pages/home/Home";
import HeadToHead from "./pages/games/headtohead/HeadToHead";
import Placement from "./pages/games/placement/Placement";
import Bracket from "./pages/games/bracket/Bracket";
import PickTheProps from "./pages/games/picktheprops/PickTheProps";

class App extends Component {
    constructor(props) {
        super(props);
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
    
        this.state = {
            /*currentAuth: undefined,*/
            modal: {show: false, text: ""}
        };
    }

    showModal(new_text){
        this.setState({
            modal:{show: true, text: new_text}
        }, () => {
            let dom = document.querySelector(".master-modal .body");
            if(dom && this.state.modal.text != ""){
                dom.innerHTML = "";
                let text = this.state.modal.text;
                text = text.split(/(?:\r\n|\r|\n)/g);
                for(var i=0; i<text.length; i++){
                    if(text[i]) {
                        let p = document.createElement("p");
                        p.textContent = text[i];
                        p.classList.add("mb-0")
                        dom.appendChild(p);
                    }
                }
            }
        })
    }

    hideModal(){
        this.setState({
            modal: {show: false, text: ""}
        })
    }

    render() {
        return (
            <div>
                <ErrorBoundary>
                    <Modal className="master-modal" show={this.state.modal.show} animation={true} centered>
                        <Modal.Body className='text-center' style={{"fontSize": "1.2em", "color": "white"}}>
                            <FontAwesomeIcon icon={faCircleNotch} className="fa-2x fa-spin mb-1" style={{"--fa-animation-duration": "2s"}}/>
                            <div className="body"></div>
                        </Modal.Body>
                    </Modal>
                    <Routes>
                        <Route path="/" element={<PrivateRoute/>}>
                            <Route path="/" element={<Home showModal={this.showModal} hideModal={this.hideModal} />} />
                            <Route path="/home" element={<Home showModal={this.showModal} hideModal={this.hideModal} />} />
                            <Route path="/head_to_head" element={<HeadToHead showModal={this.showModal} hideModal={this.hideModal} />} />
                            <Route path="/placement" element={<Placement showModal={this.showModal} hideModal={this.hideModal} />} />
                            <Route path="/bracket" element={<Bracket showModal={this.showModal} hideModal={this.hideModal} />} />
                            <Route path="pick_the_props" element={<PickTheProps showModal={this.showModal} hideModal={this.hideModal} />} />
                            <Route path="/guest/home" element={<GuestHome showModal={this.showModal} hideModal={this.hideModal} />} />
                        </Route>
                        <Route path="/guest/login" element={<GuestLogin showModal={this.showModal} hideModal={this.hideModal} />} />
                        <Route path="/login" element={<Login showModal={this.showModal} hideModal={this.hideModal} />} />
                        <Route path="/logout" element={<Logout />} />
                    </Routes>
                </ErrorBoundary>
            </div>
        );
    }
}

export default App;