import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import htmlParse from 'html-react-parser';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHandPointer, faXmark, faUpDown, faHandPointRight } from '@fortawesome/free-solid-svg-icons'

import moment from 'moment';

import { withRouter } from '../../../with-router';
import GameService from "../../../services/game";
import FirebaseService from "../../../services/firebase";

import GameTimeout from "../../../components/GameTimeout";
import TextModal from "../../../components/TextModal";
import ErrorModal from "../../../components/ErrorModal";
import Numpad from "../../../components/Numpad";

import './Placement.css';

class Placement extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page_ready: false,
            redirect: null,
            venue: null,
            promo: null,
            user_picks: [],
            player: null,
            ticket: null,
            current_round: 0,
            total_picks: 0,
            success_modal: false,
            tiebreaker_modal: false,
            help_modal: false,
            how_to_play_modal: false,
            error_modal: {show: false, title: "", text: ""},
            ticket_modal: false,
            quick_pick: false,
            tiebreaker: 0,
            block: false,
            tutorial: false,
            timeout: 60000
        };
        this.current_time = moment();
    }
  
    componentDidMount() {
        if(this.props.router.location.state == null){
            this.setState({ redirect: {path: localStorage.getItem("demo_user") ? "/guest/home" : '/home', state: null} })
            return;
        }

        this.props.showModal("Loading...");
        let venue = this.props.router.location.state.venue;
        let promo = this.props.router.location.state.promo;
        
        if(promo.league.pickscreen_background_image_url) this.backgroundImage(promo.league.pickscreen_background_image_url);
        else if(venue.pickscreen_background_image_url) this.backgroundImage(venue.pickscreen_background_image_url);
        
        let player_info = this.props.router.location.state.player;
        let played = -1;
        if(player_info.ids_of_promos_played != null && player_info.ids_of_promos_played.length > 0) played = player_info.ids_of_promos_played.findIndex(a => a == promo.id);
        
        FirebaseService.logEvent("play_promo_start", {"venue_id": venue.id, "card_num": player_info.card_number, "promo_id": promo.id})
        GameService.getPromo(promo.id).then((data) => {
            if(data.promos[0].promo_periods.length == 0){
                this.setState({
                    page_ready: false,
                    success_modal: false,
                    tiebreaker_modal: false,
                    help_modal: false,
                    how_to_play_modal: false,
                    error_modal: {show: true, title: "Something went wrong!", text: "There are no events scheduled for today in this promotion.\nPlease return to the Lobby."},
                    ticket_modal: false,
                }, () => {
                    this.props.hideModal();
                })
            }else{
                data.promos[0].promo_periods.forEach((a, index) => {
                    var new_sorted_events = a.events
                    new_sorted_events.sort((a, b) => { return moment(a.starts_at).diff(moment(b.starts_at)) || a.id - b.id })
                    a.events = new_sorted_events
                    
                    a.events.forEach(b => {
                        b.event_choices.forEach(c => {
                            if(c.choice && c.choice.serialized_stats && this.testJSON(c.choice.serialized_stats)){
                                c.choice.serialized_stats = JSON.parse(c.choice.serialized_stats)
                            }
                        })
                    })
                })

                this.timeout_interval = null;
                this.setState({
                    page_ready: true,
                    total_picks: data.promos[0].promo_periods[0].placement_picks_count,
                    venue: venue,
                    player: player_info,
                    promo: data.promos[0],
                    success_modal: false,
                    tiebreaker_modal: false,
                    help_modal: false,
                    how_to_play_modal: played > -1 ? false : true,
                    error_modal: {show: false, title: "", text: ""},
                    ticket_modal: false,
                    tutorial: played > -1 ? false : true,
                }, () => {
                    //window.history.replaceState({}, document.title)
                    this.calcHeight();
                    this.props.hideModal();
                })
            }
        }, error => {
            console.log(error)
            this.setState({ redirect: {path: localStorage.getItem("demo_user") ? "/guest/home" : '/home', state: null} })
        });
    }

    componentWillUnmount(){
        if(this.timeout_interval) clearInterval(this.timeout_interval);
    }

    backgroundImage(bg){
        let html = document.getElementsByTagName("html");
        if(html.length > 0){
            html[0].style.backgroundImage = "url('" + bg + "')";
        }
    }

    userPick(index, position){
        let promo = this.state.promo;
        let user_picks = JSON.parse(JSON.stringify(this.state.user_picks));
        let current_pick = JSON.parse(JSON.stringify(promo.promo_periods[0].events[index].event_choices[position].choice));
        current_pick["INDEX"] = index;
        current_pick["EVENT_ID"] = promo.promo_periods[0].events[index].id;
        current_pick["EVENT_CHOICE_ID"] = promo.promo_periods[0].events[index].event_choices[position].id;
        current_pick["ROUND"] = this.state.current_round;

        if(user_picks.length > 0){
            let existing_index = user_picks.findIndex(a => a.EVENT_CHOICE_ID == current_pick.EVENT_CHOICE_ID);
            if(existing_index == -1){
                if(this.state.total_picks != this.state.user_picks.length){
                    user_picks.push(current_pick);
                }
            }else{
                user_picks.splice(existing_index, 1);
                
            }
            user_picks.sort(function(a, b){return a.ROUND - b.ROUND});
            
        }else{
            user_picks.push(current_pick);
        }

        let next_round = 0;
        user_picks.forEach(a => { 
            if(a.ROUND - next_round == 0) next_round = a.ROUND + 1;
        })

        this.setState({
            user_picks: user_picks,
            success_modal: false,
            tiebreaker_modal: false,
            help_modal: false,
            how_to_play_modal: false,
            ticket_modal: false,
            tutorial: false
        }, () => {
            let picks_element = document.querySelector(`#picks-${index}`);
            if(this.state.total_picks == this.state.user_picks.length){
                if(picks_element) picks_element.scrollIntoView();

                if(this.state.quick_pick == true){
                    this.setState({
                        quick_pick: false, block: false, tiebreaker_modal: true, current_round: this.state.user_picks.length-1
                    })
                }else{
                    this.setState({
                        quick_pick: false, block: false, current_round: this.state.user_picks.length-1
                    })
                }
            }else{
                if(picks_element) picks_element.scrollIntoView();
                this.setState({
                    current_round: next_round
                })
                if(this.state.quick_pick) {
                    setTimeout(() => { 
                        this.quickPick();
                    }, 500);
                }
            }
        })
    }

    quickPick(){
        this.setState({
            quick_pick: true,
            block: true,
            success_modal: false,
            tiebreaker_modal: false,
            help_modal: false,
            how_to_play_modal: false,
            ticket_modal: false,
        }, () => {
            let user_picks = JSON.parse(JSON.stringify(this.state.user_picks));
            let events_choices = JSON.parse(JSON.stringify(this.state.promo.promo_periods[0].events[0].event_choices));
            let new_index = 0
            user_picks.forEach(a => { 
                if(a.ROUND - new_index == 0) new_index = a.ROUND + 1;
            })
            
            if(new_index < this.state.total_picks){
                let new_position = Math.floor(Math.random() * events_choices.length);
                let existed_user_picks = user_picks.filter(a => a.EVENT_CHOICE_ID == events_choices[new_position].id);
                if(existed_user_picks.length > 0){
                    this.quickPick();
                }else{             
                    let choices_element = null;
                    choices_element = document.querySelector(`#event-0 .event-choice-${new_position}`);
                    if(choices_element) {
                        choices_element.scrollIntoView({block: "start", inline: "nearest", behavior: "smooth"});
                        setTimeout(() => { choices_element.click(); }, 500);
                    }else{
                        this.quickPick();
                    }
                }
            }else{
                this.setState({ quick_pick: false, block: false })
            }
        })
    }

    submitUserPicks(tiebreaker_input){
        let new_current_time = moment();
        var duration = moment.duration(new_current_time.diff(this.current_time));

        let user_picks = JSON.parse(JSON.stringify(this.state.user_picks))
        let eventchoiceidslist = [];
        user_picks.forEach(a => {
            eventchoiceidslist.push({ "eventid": a.EVENT_ID, "eventchoiceid": a.EVENT_CHOICE_ID, "place_prediction": a.ROUND+1, "choiceid": a.id, })
        })

        let playerid = this.state.player.id;
        let promoid = this.state.promo.id;
        let promoperiodid = this.state.promo.promo_periods[0].id
        let tiebreaker = tiebreaker_input;
        let playedatlocal = moment().format();

        this.props.showModal("Loading...");
        FirebaseService.logEvent("play_promo_done", {"venue_id": this.state.venue.id, "card_num": this.state.player.card_number, "promo_id": this.state.promo.id, "time_spent": duration.asMinutes()})
        GameService.postTicket(playerid, promoid, promoperiodid, eventchoiceidslist, tiebreaker, playedatlocal).then((data) => {
            this.current_time = moment();
            FirebaseService.logEvent("play_promo_success", {"venue_id": this.state.venue.id, "card_num": this.state.player.card_number, "promo_id": this.state.promo.id, "ticket_id": data.ticket.id})
            
            this.props.hideModal();
            this.setState({
                ticket: data.ticket,
                success_modal: true
            }, () => {
                this.startSuccessModalTimer()
            })
        }, error => {
            console.log(error)
        });
    }

    onSuccessScreen(){
        if(this.state.success_modal && !localStorage.getItem("demo_user")) {
            setTimeout(() => {
                window.print();
            },500)
        }
    }

    startSuccessModalTimer(){
        let seconds_dom = document.querySelector('.success-modal .seconds');
        var timeout = 15;
        if(seconds_dom) seconds_dom.innerHTML = timeout;
        this.timeout_interval = setInterval(() => {
            if(timeout > 0){
                timeout = timeout - 1;
                if(seconds_dom) seconds_dom.innerHTML = timeout;
            }else if(timeout == 0){
                let new_current_time = moment();
                var duration = moment.duration(new_current_time.diff(this.current_time));
                FirebaseService.logEvent("play_promo_success_exit", {"venue_id": this.state.venue.id, "card_num": this.state.player.card_number, "promo_id": this.state.promo.id, "time_spent":  duration.asMinutes(), "manual_press": false})
                
                if(this.timeout_interval) clearInterval(this.timeout_interval);
                this.setState({ redirect: {path: localStorage.getItem("demo_user") ? "/guest/home" : '/home', state: null} })
            }
        }, 1000)
    }

    toggleTiebreakerModal(new_show){
        this.setState({
            success_modal: new_show ? false : this.state.success_modal,
            tiebreaker_modal: new_show,
            help_modal: new_show ? false : this.state.help_modal,
            how_to_play_modal: new_show ? false : this.state.how_to_play_modal,
        })
    }

    submitTiebreaker(new_input){
        this.setState({
            tiebreaker: new_input
        }, () => {
            this.toggleTiebreakerModal(false);
            this.submitUserPicks(new_input);
        })
    }

    clearUserPicks(e){
        e.preventDefault();
        this.setState({
            user_picks: [],
            success_modal: false,
            tiebreaker_modal: false,
            help_modal: false,
            how_to_play_modal: false,
            ticket_modal: false,
            current_round: 0,
        }, () => {
            let choices_element = document.querySelector('#event-0');
            if(choices_element) choices_element.scrollIntoView({behavior: "smooth"});
        })
    }

    toggleHelpModal(new_show){
        this.setState({
            success_modal: new_show ? false : this.state.success_modal,
            tiebreaker_modal: new_show ? false : this.state.tiebreaker_modal,
            help_modal: new_show,
            how_to_play_modal: new_show ? false : this.state.how_to_play_modal,
            ticket_modal: new_show ? false : this.state.how_to_play_modal,
        })
    }

    toggleTicketModal(new_show){
        this.setState({
            success_modal: new_show ? false : this.state.success_modal,
            tiebreaker_modal: new_show ? false : this.state.tiebreaker_modal,
            help_modal: new_show ? false : this.state.help_modal,
            how_to_play_modal: new_show ? false : this.state.how_to_play_modal,
            ticket_modal: new_show,
        })
    }

    determineUserPicksUI(index, choice){
        let user_picks = this.state.user_picks;
        let existing_index = user_picks.findIndex(a => a.INDEX == index && a.id == choice.id);
        if(existing_index > -1){
            return "selected";
        }
        return "";
    }

    determineUserPicksRanksUI(index, choice){
        let user_picks = this.state.user_picks;
        let existing_index = user_picks.findIndex(a => a.INDEX == index && a.id == choice.id);
        if(existing_index > -1){
            let rank = user_picks[existing_index]["ROUND"]
            return <div className="rank">{rank+1}<sup>{rank == 0 ? "st" : rank == 1 ? "nd" : rank == 2 ? "rd" : "th"}</sup></div>
        }
        return null;
    }

    serializedStatsUI(choice){
        if(typeof choice.serialized_stats == "string") return;
        
        let dom = [];
        Object.keys(choice.serialized_stats).map(function(key, index) {
            if(choice.serialized_stats[key]){
                let key_string = key.charAt(0).toUpperCase() + key.slice(1);
                let value_string = choice.serialized_stats[key];
                if(typeof value_string == "string") value_string = value_string.charAt(0).toUpperCase() + value_string.slice(1);
                
                dom.push(<div key={index} className="container m-0 px-2 text-start text-truncate">
                    <span className="title">{key_string}</span><br />
                    <span className="value">{value_string}</span>
                </div>)
            }
        })
        return dom;
    }

    calcHeight(){
        let top_bar = document.querySelector('.top-bar');
        let margin_offset_height = 16;
        
        let total_offset_height = top_bar.offsetHeight + (margin_offset_height*1); 
        let main_container = document.querySelector('.scroll-container');
        if(main_container){
            main_container.style.height = "calc(100vh - " + total_offset_height + "px)";
        }

        let total_offset_height2 = top_bar.offsetHeight + margin_offset_height; 
        let ticket_container = document.querySelector('.ticket-container');
        if(ticket_container){
            ticket_container.style.height = "calc(100vh - " + total_offset_height2 + "px)";
        }

        let btn_submit = document.querySelector('.btn-submit');
        let btn_clear = document.querySelector('.btn-clear');
        let user_picks_container_title = document.querySelector('.user-picks-container .title')
        
        let total_offset_height3 = top_bar.offsetHeight + btn_submit.offsetHeight + btn_clear.offsetHeight + user_picks_container_title.offsetHeight + margin_offset_height; 
        let user_picks_scroll_container = document.querySelector('.user-picks-scroll-container');
        if(user_picks_scroll_container){
            user_picks_scroll_container.style.maxHeight = "calc(100vh - " + (total_offset_height3 + 15) + "px)";
        }

        //tutorial width
        let btn_tutorial = document.querySelector('.btn-tutorial');
        let tutorial_padding = document.querySelector('.tutorial-padding');
        if(btn_tutorial){
            btn_tutorial.style.width = ((main_container.offsetWidth-15) + "px");
        }
        if(tutorial_padding){
            tutorial_padding.style.height = (btn_tutorial.offsetHeight + 10 + "px");
        }
    }

    onTimeout(){
        this.setState({
            success_modal: false,
            tiebreaker_modal: false,
            help_modal: false,
            how_to_play_modal: false,
            ticket_modal: false,
        })
    }

    onToggleTextModal(new_state){
        this.setState({ timeout: new_state ? 60000*3 : 60000 })
    }

    testJSON(text) {
        if (typeof text !== "string") {
            return false;
        }
        try {
            JSON.parse(text);
            return true;
        } catch (error) {
            return false;
        }
    }

    goHome(){
        this.setState({ redirect: { path: localStorage.getItem("demo_user") ? "/guest/home" : '/home', state: null } });
    }

    getDefaultTiebreaker(){
        var final_event_name = "";
        var teams_vs_string_1  = this.state.promo.promo_periods[this.state.promo.promo_periods.length-1].events[this.state.promo.promo_periods[this.state.promo.promo_periods.length-1].events.length-1].event_choices[0].choice ? this.state.promo.promo_periods[this.state.promo.promo_periods.length-1].events[this.state.promo.promo_periods[this.state.promo.promo_periods.length-1].events.length-1].event_choices[0].choice.name : ""
        var teams_vs_string_2  = this.state.promo.promo_periods[this.state.promo.promo_periods.length-1].events[this.state.promo.promo_periods[this.state.promo.promo_periods.length-1].events.length-1].event_choices[1].choice ? this.state.promo.promo_periods[this.state.promo.promo_periods.length-1].events[this.state.promo.promo_periods[this.state.promo.promo_periods.length-1].events.length-1].event_choices[1].choice.name : ""
        if(teams_vs_string_1 != "" && teams_vs_string_2 != "") final_event_name = teams_vs_string_1 + " vs " + teams_vs_string_2;
        else final_event_name = this.state.promo.promo_periods[this.state.promo.promo_periods.length-1].events[this.state.promo.promo_periods[this.state.promo.promo_periods.length-1].events.length-1].name
        
        return "Enter Total Number of points to be scored in " + final_event_name + " on " + moment(this.state.promo.promo_periods[this.state.promo.promo_periods.length-1].events[this.state.promo.promo_periods[this.state.promo.promo_periods.length-1].events.length-1].starts_at).format("MMMM D");
    }

    render() {
        if (this.state.redirect) {
            return <Navigate to={this.state.redirect.path} state={this.state.redirect.state}/>
        }

        return (
            <div id="placement" className="container-fluid">
                <ErrorModal show={this.state.error_modal.show} title={this.state.error_modal.title} text={this.state.error_modal.text}/>
                { this.state.block ? <div className="invincible-wall"></div> : null }
                {(this.state.page_ready) ?
                <div>
                    <div className="receipt">
                        <div className="text-center">
                            <img src={this.state.venue.logo_url} width="100px"/>
                        </div>
                        <div className="pt-2 text-center mb-2">
                            <div className="fw-bold fs-5">
                                {this.state.venue.name}<br />
                                {this.state.promo.name}<br />
                                {this.state.promo.promo_periods.length > 0 && this.state.promo.promo_periods[this.state.promo.promo_periods.length-1].name}
                            </div>
                            {this.state.ticket && this.state.ticket.played_at_local_as_string &&
                                <div className="text-center mb-2">
                                    {moment(this.state.ticket.played_at_local_as_string).format("MMM D, YYYY h:mm a")}
                                </div>
                            }
                            <ul className="p-0 m-0 text-start" style={{listStyle: "none", }}>
                                {this.state.user_picks.length > 0 && this.state.user_picks.map((item, index) => ( 
                                    <li className="pick" key={index}>{item.ROUND+1} {item.name}</li>
                                ))}
                                <li>Tiebreaker: {this.state.tiebreaker}</li>
                            </ul>
                        </div>
                        {this.state.promo.receipt_disclaimer != "" &&
                            <div>
                                <hr className="my-1" />
                                <div className="pt-2 disclaimer">
                                    {htmlParse(this.state.promo.receipt_disclaimer)}
                                </div>
                            </div>
                        }
                        {this.state.promo.receipt_message != "" &&
                            <div>
                                <hr className="my-1" />
                                <div className="pt-2 disclaimer">
                                    {htmlParse(this.state.venue.receipt_message)}
                                </div>
                            </div>
                        }
                    </div>
                    <div className="main-container">
                        {!this.state.success_modal ? <GameTimeout {...this} timeout={this.state.timeout} onTimeout={() => this.onTimeout()}/> : null}
                        <Numpad 
                            title={"Enter Tiebreaker"}
                            body={(this.state.promo.promo_periods[this.state.promo.promo_periods.length-1].tiebreaker_question != null && this.state.promo.promo_periods[this.state.promo.promo_periods.length-1].tiebreaker_question != "") 
                                ? this.state.promo.promo_periods[this.state.promo.promo_periods.length-1].tiebreaker_question 
                                : this.getDefaultTiebreaker()}
                            image={this.state.promo.league.name.toLowerCase().includes("wrestling") || this.state.promo.league.name.includes("AEW")
                                ? "/assets/images/refree-tiebreaker.png"
                                : "/assets/images/football_tiebreaker_small.png"}
                            size="md" 
                            show_numpad={this.state.tiebreaker_modal} 
                            onSubmitInput={(new_input) => this.submitTiebreaker(new_input)} 
                            type="number"
                        />
                        <TextModal onToggle={(new_state) => this.onToggleTextModal(new_state)} title="Help" class="help_modal" help_modal={this.state.help_modal} text={this.state.promo.help_rules}/>
                        <TextModal onToggle={(new_state) => this.onToggleTextModal(new_state)} title="How To Play" class="how_to_play_modal" help_modal={this.state.how_to_play_modal} text={this.state.promo.how_to_play}/>
                        <Modal onShow={this.onSuccessScreen()} className="success-modal" show={this.state.success_modal} animation={true} centered size="sm">
                            <Modal.Header>
                                <Modal.Title className="w-100">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col p-0 d-flex align-items-center">
                                                Success!
                                            </div>
                                            <div className="col text-end p-0">
                                                <Button onClick={e => {
                                                        let new_current_time = moment();
                                                        var duration = moment.duration(new_current_time.diff(this.current_time));
                                                        FirebaseService.logEvent("play_promo_success_exit", {"venue_id": this.state.venue.id, "card_num": this.state.player.card_number, "promo_id": this.state.promo.id, "time_spent":  duration.asMinutes(), "manual_press": true})
                                                        
                                                        this.goHome();
                                                    }}
                                                >
                                                    Home <span className="seconds"></span>
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="text-center">
                                    <img src={this.state.venue.logo_url} width="100px"/>
                                </div>
                                <div className="pt-2 text-center mb-2">
                                    <div className="fw-bold fs-5">
                                        {this.state.venue.name}<br />
                                        {this.state.promo.name}<br />
                                        {this.state.promo.promo_periods.length > 0 && this.state.promo.promo_periods[this.state.promo.promo_periods.length-1].name}
                                    </div>
                                    {this.state.ticket && this.state.ticket.played_at_local_as_string &&
                                        <div className="text-center mb-2">
                                            {moment(this.state.ticket.played_at_local_as_string).format("MMM D, YYYY h:mm a")}
                                        </div>
                                    }
                                    <ul className="p-0 m-0 text-start" style={{listStyle: "none", }}>
                                        {this.state.user_picks.length > 0 && this.state.user_picks.map((item, index) => ( 
                                            <li className="pick" key={index}>{item.ROUND+1} {item.name}</li>
                                        ))}
                                        <li>Tiebreaker: {this.state.tiebreaker}</li>
                                    </ul>
                                </div>
                                {this.state.promo.receipt_disclaimer != "" &&
                                    <div>
                                        <hr className="my-1" />
                                        <div className="pt-2 disclaimer">
                                            {htmlParse(this.state.promo.receipt_disclaimer)}
                                        </div>
                                    </div>
                                }
                                {this.state.promo.receipt_message != "" &&
                                    <div>
                                        <hr className="my-1" />
                                        <div className="pt-2 disclaimer">
                                            {htmlParse(this.state.venue.receipt_message)}
                                        </div>
                                    </div>
                                }
                            </Modal.Body>
                        </Modal>
                        <Modal className="ticket-modal" onHide={e => this.toggleTicketModal(false)} show={this.state.ticket_modal} animation={true} centered size="sm">
                            <Modal.Header closeButton>
                                <Modal.Title className="w-100">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col p-0 d-flex align-items-center">
                                                Ticket
                                            </div>
                                        </div>
                                    </div>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="text-center mb-2">
                                    <ul className="p-0 m-0 text-start" style={{listStyle: "none", }}>
                                        {this.state.user_picks.length > 0 && this.state.user_picks.map((item, index) => ( 
                                            <li className="pick" key={index}>{item.name}</li>
                                        ))}
                                    </ul>
                                </div>
                            </Modal.Body>
                        </Modal>
                        <div className="row p-2 top-bar" style={{backgroundColor: "lightgray"}}>
                            <div className="col-auto text-start d-flex align-items-center">
                                <Button onClick={e => {
                                        let new_current_time = moment();
                                        var duration = moment.duration(new_current_time.diff(this.current_time));
                                        FirebaseService.logEvent("play_promo_exit", {"venue_id": this.state.venue.id, "card_num": this.state.player.card_number, "promo_id": this.state.promo.id, "time_spent":  duration.asMinutes(), "manual_press": true})
                                        
                                        this.goHome();
                                    }}
                                    variant="secondary" className="me-2" 
                                >
                                    Home
                                </Button>
                                <Button variant="secondary" onClick={e => this.toggleHelpModal(true)}>
                                    Help
                                </Button>
                            </div>
                            <div className="col text-center header-title">
                                {this.state.promo.name}
                            </div>
                            <div className="col-auto text-end">
                                {/*<Button variant="secondary" className="me-2">
                                    Official Rules
                                </Button>
                                <Button variant="secondary">
                                    History
                                </Button>*/}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <div className="container mt-2">
                                    <div className="row">
                                        <div className="col-9 choice-parent-container">
                                            <div>
                                                <div className="row scroll-container" style={{borderRadius: "15px"}}>
                                                    <div className="col px-0">
                                                        <div className="p-2 mb-1" style={{position: "sticky", top: "0", zIndex: 2, backgroundColor: "lightgray", borderRadius: "15px"}}>
                                                            <div className="row d-flex align-items-center">
                                                                <div className="col text-center fs-4 fw-bold">
                                                                    Pick for {this.state.current_round+1}<sup>{this.state.current_round == 0 ? "st" : this.state.current_round == 1 ? "nd" : this.state.current_round == 2 ? "rd" : "th"}</sup> place out of the top {this.state.total_picks}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="p-0">   
                                                            {this.state.promo.promo_periods[0] && this.state.promo.promo_periods[0].events.map((item, index) => ( 
                                                                <div key={"event-" + index} id={"event-" + index} className={index > 0 ? "mt-5 pt-2 pb-0 px-2 text-center" : "pt-2 pb-0 px-2 text-center"} style={{backgroundColor: "whitesmoke", borderRadius: "15px", overflow: "hidden"}}>
                                                                    <div className="row pb-1 fs-6 fw-bold">
                                                                        <div className="col-auto text-start">
                                                                            {moment(item.starts_at).format("MMMM D")}
                                                                        </div>
                                                                        {item.name && 
                                                                            <div className="col text-center">
                                                                                {item.name}
                                                                            </div>
                                                                        }
                                                                        <div className="col-auto text-end">
                                                                            {moment(item.starts_at).format("hh:mm A z")}
                                                                        </div>
                                                                    </div>
                                                                    <div className="row d-flex flex-row four-item">
                                                                        {item.event_choices.map((inner_item, inner_index) => (
                                                                            inner_item.choice && 
                                                                                <div key={"event-choice-" + inner_index} className={"position-relative item-pick p-0 event-choice-" + inner_index + " " + this.determineUserPicksUI(index, inner_item.choice)}
                                                                                    onClick={e => this.userPick(index, inner_index)}
                                                                                >
                                                                                    <div>
                                                                                        <div className="position-relative img border-left-whitesmoke">
                                                                                            <img src={inner_item.choice.logo_image_url}/>
                                                                                            {this.determineUserPicksRanksUI(index, inner_item.choice)}
                                                                                        </div>
                                                                                        <div className="label border-left-whitesmoke text-truncate">
                                                                                            {inner_item.choice.name.split(/\ (?=[^\ ]+$)/)[0]}
                                                                                            <br />
                                                                                            {inner_item.choice.name.split(/\ (?=[^\ ]+$)/)[1]}
                                                                                        </div>
                                                                                        {inner_item.choice.serialized_stats && 
                                                                                            <div className="stats d-flex flex-row">
                                                                                                {this.serializedStatsUI(inner_item.choice)}
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                    {this.state.tutorial && index == 0 && inner_index == 0 &&
                                                                                        <div className="position-absolute" style={{top: "calc(50% - 50px)", zIndex: 1}}><img src="/assets/images/pointer.gif" width={"50%"}/></div>
                                                                                    }
                                                                                    {item.event_choices.length - (inner_index+1) > 1 && <hr className="m-0"/>}
                                                                                </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                        {this.state.tutorial &&
                                                            <div>
                                                                <Button className="btn-tutorial m-2 position-fixed" style={{bottom: 0, filter: "drop-shadow(-5px 5px 4px rgba(0,0,0,0.5))"}}>
                                                                    <div className="container">
                                                                        <div className="row mb-1">
                                                                            <div className="col fs-3">
                                                                                <FontAwesomeIcon icon={faHandPointer} size="1x" />&nbsp;&nbsp;<strong>Let's get started</strong>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col fs-5 text-center d-flex align-items-center justify-content-center" style={{borderRight: "1px solid white"}}>
                                                                                <div>
                                                                                    <div><FontAwesomeIcon icon={faHandPointer} size="1x" /></div>
                                                                                    <div className=""><strong>Tap to Select</strong></div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col fs-5 text-center d-flex align-items-center justify-content-center" style={{borderRight: "1px solid white"}}>
                                                                                <div>
                                                                                    <div>
                                                                                        <FontAwesomeIcon icon={faXmark} size="1x" />
                                                                                        <FontAwesomeIcon icon={faHandPointer} size="1x" />
                                                                                    </div>
                                                                                    <div className="d-flex align-items-center align-items-stretch"><strong>Tap to Deselect</strong><br /></div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col fs-5 text-center d-flex align-items-center justify-content-center">
                                                                                <div>
                                                                                    <div>
                                                                                        <FontAwesomeIcon icon={faUpDown} size="1x" />
                                                                                        <FontAwesomeIcon icon={faHandPointer} size="1x" />
                                                                                    </div>
                                                                                    <div className="d-flex align-items-center align-items-stretch"><strong>Press & Slide to scroll “More”</strong></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Button>
                                                            </div>
                                                        }
                                                        {this.state.tutorial &&
                                                            <div className="tutorial-padding"></div>
                                                        }
                                                        <div className="mobile-padding"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col ticket-parent-container">
                                            <div className="container ticket-container pt-0 custom-scroll" style={{backgroundColor: "rgba(0, 0, 0, 0.5)"}}>
                                                <div className="row px-2 pt-2 btn-submit">
                                                    <div className="col text-center d-grid p-0">
                                                        <Button variant="secondary" onClick={e => this.clearUserPicks(e)} disabled={this.state.user_picks.length > 0 ? false : true}>
                                                            Clear All
                                                        </Button>
                                                    </div>
                                                </div>

                                                <div className="row px-2 user-picks-container">
                                                    <div className="pt-3 px-0 col text-start title">
                                                        <div className="px-2" style={{backgroundColor: "gray", color: "white"}}>My Ticket</div>
                                                    </div>
                                                    <ul className="m-0 p-0 user-picks-scroll-container">
                                                        {this.state.user_picks.length == 0 &&
                                                            <li>Please make a selection</li>
                                                        }
                                                        {this.state.user_picks.length > 0 &&
                                                            <li className="px-2 fw-bold" style={{backgroundColor: "silver"}}>{this.state.promo.promo_periods[0].name}</li>
                                                        }
                                                        {this.state.user_picks.length > 0 && this.state.user_picks.map((item, index) => ( 
                                                            <li key={index} id={"picks-" + index}>{item.ROUND+1} {item.name}</li>
                                                        ))}
                                                    </ul>
                                                </div>

                                                <div className="row px-2 pb-2 btn-clear">
                                                    <div className="col text-center d-grid p-0">
                                                        {this.state.user_picks.length != this.state.total_picks &&
                                                            <Button variant="success" onClick={e => this.quickPick()}>
                                                                Quick Pick
                                                            </Button>
                                                        }
                                                        {this.state.user_picks.length == this.state.total_picks &&
                                                            <div className="position-relative">
                                                                <div className="d-grid">
                                                                    <Button className="btn-custom-submit" variant="success" onClick={() => this.toggleTiebreakerModal(true)}>
                                                                        Submit
                                                                    </Button>
                                                                </div>
                                                                <div className="position-absolute" style={{top: "0px", zIndex: 1}}>
                                                                    <Button className="m-2 position-absolute" style={{width: "600px", right: "0px", top: "-33px", filter: "drop-shadow(-5px 5px 4px rgba(0,0,0,0.5))"}}>
                                                                        <div className="container">
                                                                            <div className="row">
                                                                                <div className="col fs-3">
                                                                                    <strong>Great Picks - Your ticket is full&nbsp;&nbsp;<FontAwesomeIcon icon={faHandPointRight} size="1x" /></strong>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col fs-5">
                                                                                    Tap the Submit button when you are done
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Button>
                                                                </div>
                                                                <div className="position-absolute text-end" style={{top: "0px", zIndex: 1, pointerEvents: "none"}}><img src="/assets/images/pointer.gif" width={"30%"}/></div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                                {(this.state.promo.league.name.toLowerCase().includes("wrestling") || this.state.promo.league.name.includes("AEW")) &&
                                                    <div className="row p-0 m-0 mt-2">
                                                        <img src="/assets/images/ads2.jpg" width={"100%"} className="p-0"/>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {/*End of main container*/}
                                </div>
                            </div>
                        </div>
                        <div className="mobile-ticket-parent-container" style={{backgroundColor: "lightgray"}}>
                            <div className="p-2">
                                <div className="row">
                                    <div className="col text-end">
                                        <Button variant="secondary" onClick={e => this.clearUserPicks(e)} disabled={this.state.user_picks.length > 0 ? false : true}>
                                            Clear All
                                        </Button>
                                    </div>
                                    <div className="col-auto text-center">
                                        <Button variant="secondary" onClick={e => this.toggleTicketModal(true)} disabled={this.state.user_picks.length > 0 ? false : true}>
                                            Ticket
                                        </Button>
                                    </div>
                                    <div className="col text-start">
                                        {this.state.user_picks.length != this.state.total_picks &&
                                            <Button variant="success" onClick={e => this.quickPick()}>
                                                Quick Pick
                                            </Button>
                                        }
                                        {this.state.user_picks.length == this.state.total_picks &&
                                            <Button className="btn-custom-submit" variant="success" onClick={() => this.toggleTiebreakerModal(true)}>
                                                Submit
                                            </Button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> : null
                }
            </div>
        );
    }
}

export default withRouter(Placement);