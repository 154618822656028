import React, { Component } from "react";

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

import { withRouter } from '../with-router';
import AuthService from "../services/auth";
import GameService from "../services/game";

const DEMO_USERNAME = process.env.REACT_APP_DEMO_USERNAME;
const DEMO_PASSWORD = process.env.REACT_APP_DEMO_PASSWORD;

const required = value => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};

class Login extends Component {
    constructor(props) {
        super(props);
        this.handleLogin = this.handleLogin.bind(this);
        
        this.state = {
            page_ready: false,
            email: "",
            firstname: "",
            lastname: "",
            phonenumber: "",
            loading: false,
            message: ""
        };
    }

    componentDidMount(){
        this.setState({
            page_ready: true,
        })
    }

    handleLogin(e) {
        if(e) e.preventDefault();
        this.setState({
            message: "",
            loading: true
        });

        this.form.validateAll();

        if (this.checkBtn.context._errors.length === 0) {
            AuthService.login(DEMO_USERNAME, DEMO_PASSWORD).then(() => {
                this.saveUser();
            },
            error => {
                const resMessage = (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                    error.message ||
                    error.toString();

                this.setState({
                    loading: false,
                    message: resMessage,
                });
            });
        } else {
            this.setState({
                loading: false
            });
        }
    }

    saveUser(){
        let characters = "0123456789";  
        let lenString = 24;
        let card = '';

        for (var i=0; i<lenString; i++) {  
            var rnum = Math.floor(Math.random() * characters.length);  
            card += characters.substring(rnum, rnum+1);  
        }  

        GameService.postNewGuestPlayer(card, this.state.email, this.state.firstname, this.state.lastname, this.state.phonenumber).then((data) => {
            localStorage.setItem("demo_user", JSON.stringify(data));

            this.props.router.navigate("/guest/home");
            window.location.reload();

        }).catch(error => {
            console.log(error)
        }).finally(() => {
            this.props.hideModal();
        });
    }

    onKeyboardInputChange = (e, target) => {
        this.setState({
            [target]: e.target.value
        });
    }

    render() {
        return (
            <div className="col-md-12">
                <div className="card card-container">
                    <img className="pb-4"
                        src="/assets/images/logo-black.png"
                    />
                    <div className="text-center pb-4">
                        Welcome to Database Dynamics<br />
                        Guest Access Demonstration
                    </div>
                    <Form
                        onSubmit={this.handleLogin}
                        ref={c => {
                            this.form = c;
                        }}
                    >
                        <div className="form-group">
                            <label htmlFor="username">Email<span style={{fontWeight: "bold", color: "red"}}>*</span></label>
                            <Input
                                type="email"
                                className="form-control"
                                name="email"
                                value={this.state.email}
                                onChange={e => this.onKeyboardInputChange(e, "email")}
                                validations={[required]}
                            />
                        </div>

                        <div className="form-group row">
                            <div className="col">
                                <label htmlFor="firstname">First Name</label>
                                <Input
                                    type="firstname"
                                    className="form-control"
                                    name="firstname"
                                    value={this.state.firstname}
                                    onChange={e => this.onKeyboardInputChange(e, "firstname")}
                                />
                            </div>
                            <div className="col">
                                <label htmlFor="lastname">Last</label>
                                <Input
                                    type="lastname"
                                    className="form-control"
                                    name="lastname"
                                    value={this.state.lastname}
                                    onChange={e => this.onKeyboardInputChange(e, "lastname")}
                                />
                            </div>
                        </div>

                        <div className="form-group">
                            <label htmlFor="phonenumber">Phone Number</label>
                            <Input
                                type="phonenumber"
                                className="form-control"
                                name="phonenumber"
                                value={this.state.phonenumber}
                                onChange={e => this.onKeyboardInputChange(e, "phonenumber")}
                            />
                        </div>

                        <div className="form-group">
                            <div className="d-grid gap-2" style={{marginTop: "10px"}}>
                                <button className="btn btn-primary btn-block" disabled={this.state.loading}>
                                    {this.state.loading && (
                                        <span className="spinner-border spinner-border-sm"></span>
                                    )}
                                    <span>Continue as Guest</span>
                                </button>
                            </div>
                        </div>

                        {this.state.message && (
                            <div className="form-group">
                            <div className="alert alert-danger" role="alert">
                                {this.state.message}
                            </div>
                            </div>
                        )}
                        <CheckButton
                            style={{ display: "none" }}
                                ref={c => {
                                this.checkBtn = c;
                            }}
                        />
                    </Form>
                </div>
            </div>
        );
    }
}

export default withRouter(Login);