import React, { Component } from "react";
import { Navigate, Link } from "react-router-dom";
import { Modal, Form, Button }  from 'react-bootstrap';

import moment from 'moment';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import VirtualKeyboard from "../../components/VirtualKeyboard";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faIdCard, faFlag, faCaretLeft, faCaretRight, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'

import { withRouter } from '../../with-router';
import AuthService from "../../services/auth";
import GameService from "../../services/game";
import FirebaseService from "../../services/firebase";

import Numpad from "../../components/Numpad";
import './Home.css';

const CHAR_BeginningOfSubstring = ';';
const CHAR_EndingOfSubstring = '?';

const arrowStyles = {
    position: 'absolute',
    zIndex: 2,
    top: "0",
    height: "100%",
    borderRadius: "0",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    border: "0px"
};

class Home extends Component {
    constructor(props) {
        super(props);

        this.processing = false;
        this.state = {
            vid: "",
            page_ready: false,
            read_card_ready: false,
            redirect: null,
            venue: null,
            promos: [],
            eariliest_date: null,
            card_input: "",
            modal: {show: false, text: ""},
            player_error_modal: {show: false, title: "", body: ""},
            pin_modal: false,
            register_modal: false,
            update_modal: false,
            dupe_email_modal: false,
            welcome_modal: false,
            register_input: {email: "", firstname: "", lastname: "", valid: false},
            input_name: "firstname",
            player: null,
            click: false,
        };
        this.timeout_function = null;
        this.register_timeout_function = null;
        this.keyboard = React.createRef();

        const locale = window.navigator.language || 'en-us'
        this.date = new Date();
        this.dayNum = this.date.getDate();
        this.month = this.date.getMonth();
        this.dayName = this.date.toLocaleString(locale, { weekday: 'long' });
        this.monthName = this.date.toLocaleString(locale, { month: 'long' });
        this.year = this.date.getFullYear();
    }

    componentDidMount() {
        localStorage.removeItem("demo_user")
        this.props.showModal("Loading...")
        let local_bg = localStorage.getItem("home_bg");
        if(local_bg != null) this.backgroundImage(local_bg);

        GameService.getVenueAndGames().then((data) => {
            //console.log(data);
            let eariliest = null;
            data.promos.forEach((a, index) => {
                if(a.promoperiods.length){
                    a.promoperiods.forEach(b => {
                        if(eariliest == null) eariliest = moment(b.event_start_time);
                        else{
                            let isBefore = moment(b.event_start_time).isBefore(eariliest);
                            if(isBefore) eariliest = moment(b.event_start_time);
                        }
                    })
                }else{
                    data.promos.splice(index, 1);
                }
            })

            var new_promos = data.promos.filter(a => {
                return moment().isBetween(moment(a.promo.start_time), moment(a.promo.end_time)) && a.promo.status == 1
            });

            this.setState({
                vid: data.venue.lobby_background_image_url.includes("mp4") ? data.venue.lobby_background_image_url : "",
                eariliest_date: eariliest,
                page_ready: true,
                venue: data.venue,
                promos: new_promos
            }, () => {
                if(this.state.venue.lobby_background_image_url) this.backgroundImage(this.state.venue.lobby_background_image_url);
                else this.backgroundImage("");

                this.registerKeyInputEvent();
                this.props.hideModal();

                this.getCardNumberFromURL();

                //if(this.state.promos.length == 1) this.renderCalendarPage();
            })
        }).catch(error => {
            console.log(error)
            if(error.response.status == 401){
                AuthService.refreshAuth().then((data) => {
                    window.location.reload(false);
                }).catch(error => {
                    AuthService.logout();
                    this.setState({ redirect: { path:"/login", state: null } }, () => {
                        this.props.hideModal();
                    });
                });
            }
        }).finally(() => {
            this.startTwentyMinTimeout();
        });
    }

    componentWillUnmount(){
        if(this.timeout_function) clearTimeout(this.timeout_function);
        if(this.register_timeout_function) clearTimeout(this.register_timeout_function);
    }

    getCardNumberFromURL(){
        var card_number = "";
        if(this.props.router.location.search){
            let search_params = this.props.router.location.search;
            let params = new URLSearchParams(search_params);
            if(params.has('card_number')) card_number = params.get("card_number");
        }
        if(card_number != "" && card_number.charAt(0) == ";" && card_number.charAt(card_number.length-1) == "?"){
            this.getPlayerInfo(card_number.substring(1, card_number.length-1));
        }
    }

    startTwentyMinTimeout(){
        if(this.timeout_function) clearTimeout(this.timeout_function);
        this.timeout_function = setTimeout(() => {
            window.location.reload();
        }, 1.2e+6)
    }

    backgroundImage(bg){
        let html = document.getElementsByTagName("html");
        if(html.length > 0){
            if(bg) html[0].style.backgroundImage = "url('" + bg + "')";
            else html[0].style.backgroundImage = "";

            localStorage.setItem("home_bg", bg);
        }

        let home_bg = document.getElementsByClassName("home-background");
        if(home_bg.length > 0){
            if(bg) home_bg[0].style.backgroundImage = "url('" + bg + "')";
            else home_bg[0].style.backgroundImage = "";
        }
    }

    //Register input event for card swipes
    registerKeyInputEvent(){
        document.addEventListener("keydown", (e) => {
            if(e.key === "Tab") {
                e.preventDefault();
            }
        });

        document.addEventListener('keyup', (e) => {
            if(this.processing) return;

            if(e.key === CHAR_BeginningOfSubstring){
                if(this.state.modal.show == false){
                    this.setState({ read_card_ready: true, modal: {show: true, text: "Reading card..."}, pin_modal: false })
                }
            }else if(e.key === CHAR_EndingOfSubstring){
                if(this.state.modal.show == true){
                    var card_num = this.state.card_input
                    this.setState({ read_card_ready: false, modal: {show: false, text: ""}, card_input: "" }, () => {
                        this.getPlayerInfo(card_num);
                    })
                }

            }else if(this.state.read_card_ready == true){
                var new_card_num = this.state.card_input + e.key
                this.setState({  card_input: new_card_num })
            }
        });
    }

    getPlayerInfo(card_num){
        if(card_num.toLowerCase() == "logout"){
            this.setState({ redirect: { path:"/logout", state: null } });
            return;
        }

        this.processing = true;
        this.props.showModal("Getting player info...")

        let new_card_num = card_num
        if(card_num == "SYCUANTEST999"){
            new_card_num = "007017071400977383";
        } else if (card_num == "PECHANGATEST1"){
            new_card_num = "011026000093801341"
        } else if (card_num == "PECHANGATEST2"){
            new_card_num = "011026000092703533";
        }

        FirebaseService.logEvent("card_swipe", {"venue_id": this.state.venue.id, "card_num": new_card_num})
        GameService.getPlayer(new_card_num).then((data) => {
            //console.log(data)
            if(data.card_number == null) data.card_number = new_card_num
            this.setState({
                player: data
            }, () => {
                if(
                    ((data.first_name == "" || data.first_name == null) ||
                    (data.last_name == "" || data.last_name == null) ||
                    (data.email == "" || data.email == null)) && !this.isPechangaCasinoSpecialCase()
                ){
                    this.clearAllInput();

                    var curr_email = (data.email == "" || data.email == null) ? "" : data.email
                    var curr_first_name = (data.first_name == "" || data.first_name == null) ? "" : data.first_name
                    var curr_last_name = (data.last_name == "" || data.last_name == null) ? "" : data.last_name
                    this.setState({
                        register_input: {email: curr_email, firstname: curr_first_name, lastname: curr_last_name, valid: false}
                    }, () => {
                        this.toggleUpdateModal(true);
                    })
                }else{
                    if(this.state.promos.length == 1){
                        this.playGame(this.state.promos[0]);
                    }else{
                        this.setState({
                            welcome_modal: true
                        })
                    }
                }
            })

        }).catch(error => {
            console.log(error)
            if(error.response.status == 404){
                if(this.isPechangaCasinoSpecialCase()){
                    this.ifPechangaCasinoCardNumberValidCreateNewPlayer(new_card_num);
                }else{
                    this.showRegistrationModal(new_card_num);
                }

            }else{
                this.processing = false;
            }
        }).finally(() => {
            this.startTwentyMinTimeout();
            this.props.hideModal();
        });
    }


    isPechangaCasinoSpecialCase(){
        if(this.state.venue.name.toLowerCase().includes("pechanga")){
            return true;
        }
        return false;
    }

    ifPechangaCasinoCardNumberValidCreateNewPlayer(card_num) {
        GameService.getPlayerCardNumberRegistered(card_num).then( response => {
            if(response != null){
                if(response.user_exists){
                    // Create the new player:
                    this.setState({
                        card_input: card_num,
                        register_modal: false,
                        register_input: {email: "", firstname: "", lastname: "", valid: false}
                    });
                    this.registerNewPlayer(this, card_num);
                }else{
                    this.tooglePlayerErrorModal(true, "Something went wrong!", "We couldn't complete your request.\nPlease see the players club.");                }
            }
        }).catch(error => {
            console.log(error);
            this.tooglePlayerErrorModal(true, "Something went wrong!", "We couldn't complete your request.\nPlease see the players club.");
        });
    }

    showRegistrationModal(card_num){
        FirebaseService.logEvent("register_card_prompt", {"venue_id": this.state.venue.id, "card_num": card_num})
        this.setState({
            card_input: card_num,
            register_input: {email: "", firstname: "", lastname: "", valid: false}
        }, () => {
            this.clearAllInput();
            this.toggleRegisterModal(true);
        })
    }



    submitPin(new_input){
        //console.log(new_input);
        //this.redirectToGame();

        if(this.state.promos.length == 1){
            this.playGame(this.state.promos[0]);
        }else{
            this.setState({
                pin_modal: false,
                welcome_modal: true
            })
        }
    }

    checkExistingPlayer(e){
        e.preventDefault();

        let email = this.state.register_input.email;
        let firstname = this.state.register_input.firstname;
        let lastname = this.state.register_input.lastname;

        this.props.showModal("Registering new player...")
        this.toggleRegisterModal(false);

        GameService.postSearchPlayer(email, firstname, lastname).then((data) => {
            this.setState({
                player: data
            }, () => {
                this.toggleDupeEmailModal(true);
            })
        }).catch(error => {
            console.log(error);
            if(error.response.status == 404){
                this.registerNewPlayer();
            }else{
                this.processing = false;
            }
        }).finally(() => {
            this.startTwentyMinTimeout();
            this.props.hideModal();
        });
    }

    registerNewPlayer(e, card_number = ""){
        // if(e) e.preventDefault();

        let email = this.state.register_input.email;
        console.log("Card Number parameter is");
        console.log(card_number);
        let card = card_number != "" ? card_number : this.state.card_input;
        let firstname = this.state.register_input.firstname;
        let lastname = this.state.register_input.lastname;

        this.props.showModal("Registering new player...")
        this.toggleDupeEmailModal(false);
        this.toggleRegisterModal(false);

        FirebaseService.logEvent("register_card", {"venue_id": this.state.venue.id, "card_num": card, "email": email});
        GameService.postNewPlayer(card, email, firstname, lastname).then((data) => {
            if(data.card_number == null) data.card_number = card
            this.setState({
                card_input: "",
                register_modal: false,
                register_input: {email: "", firstname: "", lastname: "", valid: false},
                player: data
            }, () => {
                if(this.state.promos.length == 1){
                    this.playGame(this.state.promos[0]);
                }else{
                    this.setState({
                        welcome_modal: true
                    })
                }
            });
        }).catch(error => {
            console.log(error);
            this.processing = false;
        }).finally(() => {
            this.startTwentyMinTimeout();
            this.props.hideModal();
        });
    }

    updatePlayer(e){
        if(e) e.preventDefault();

        var curr_player = JSON.parse(JSON.stringify(this.state.player))
        let firstname = this.state.register_input.firstname;
        let lastname = this.state.register_input.lastname;
        let email = this.state.register_input.email;
        let playerid = curr_player.id;

        this.props.showModal("Updating player info...")
        this.toggleUpdateModal(false);

        GameService.postUpdatePlayer(playerid, firstname, lastname, email).then((data) => {
            curr_player.first_name = firstname;
            curr_player.last_name = lastname;
            curr_player.email = email;
            this.setState({
                card_input: "",
                register_modal: false,
                register_input: {email: "", firstname: "", lastname: "", valid: false},
                player: curr_player
            }, () => {
                if(this.state.promos.length == 1){
                    this.playGame(this.state.promos[0]);
                }else{
                    this.setState({
                        welcome_modal: true
                    })
                }
            });
        }).catch(error => {
            console.log(error);
            this.processing = false;
        }).finally(() => {
            this.startTwentyMinTimeout();
            this.props.hideModal();
        });
    }

    addCardToPlayer(e){
        if(e) e.preventDefault();

        var new_player = JSON.parse(JSON.stringify(this.state.player))
        let card = this.state.card_input;
        let playerid = new_player.id;

        this.props.showModal("Registering new player...")
        this.toggleDupeEmailModal(false);
        this.toggleRegisterModal(false);

        FirebaseService.logEvent("register_card", {"venue_id": this.state.venue.id, "card_num": card, "email": new_player.email});
        GameService.postAddPlayerCard(playerid, card).then((data) => {
            new_player.card_number = card
            this.setState({
                card_input: "",
                register_modal: false,
                register_input: {email: "", firstname: "", lastname: "", valid: false},
                player: new_player
            }, () => {
                if(this.state.promos.length == 1){
                    this.playGame(this.state.promos[0]);
                }else{
                    this.setState({
                        welcome_modal: true
                    })
                }
            });
        }).catch(error => {
            console.log(error);
            if(error.response.status == 409){
                this.tooglePlayerErrorModal(true, "Something went wrong!", "We couldn't complete your request.\nPlease ask for assistance.");
            }
            this.processing = false;
        }).finally(() => {
            this.startTwentyMinTimeout();
            this.props.hideModal();
        });
    }

    playGame(item){
        this.setState({
            welcome_modal: false
        }, () => {
            this.props.showModal("Loading game...")

            let cardnumber = this.state.player.card_number
            let promoid = item.promo.id;
            let time = moment().format();

            FirebaseService.logEvent("navigate_to_promo", {"venue_id": this.state.venue.id, "card_num": cardnumber, "promo_id": promoid})
            GameService.playGame(cardnumber, promoid, time).then((data) => {
                if(data.can_play_promo == true){
                    let url = "";
                    switch(item.promo.game_type){
                        case 0:
                            url = "head_to_head";
                            break;
                        case 1:
                            url = "placement";
                            break;
                        case 2:
                            url = "bracket";
                            break;
                        case 3:
                            url = "pick_the_props";
                            break;
                        default:
                    }
                    this.redirectToGame(url, item);
                }else{
                    this.tooglePlayerErrorModal(true, "Please come back tomorrow", "You may only play once per day.\nPlease play again tomorrow.");
                }
            }).catch(error => {
                console.log(error)
                this.processing = false;
            }).finally(() => {
                this.startTwentyMinTimeout();
                this.props.hideModal();
            });;
        })
    }

    redirectToGame(path, promos){
        let venue = this.state.venue;
        let promo = promos.promo;
        this.setState({
            redirect: {
                path: "/" + path,
                state: {
                    venue: venue,
                    promo: promo,
                    player: this.state.player
                }
            }
        });
    }

    onClickItem(index, item){
        //console.log(index);
        //console.log(item);
    }

    togglePinModal(new_state){
        this.setState({
            pin_modal: new_state
        })
    }

    tooglePlayerErrorModal(new_state, new_title, new_body){
        this.setState({
            player_error_modal: {show: new_state, title: new_title, body: new_body}
        }, () => {
            if(this.state.player_error_modal.show == false) this.processing = false;
        })
    }

    toggleRegisterModal(new_state){
        this.setState({
            register_modal: new_state
        }, () => {
            if(this.state.register_modal == true){
                this.startRegisterModalTimeout();

                let dom = document.querySelector('.registration-modal input[id="firstname"]');
                if(dom) dom.click();
                setTimeout(() => {
                    if(dom) dom.click();
                },100)
            }else{
                if(this.register_timeout_function) clearTimeout(this.register_timeout_function);
                this.processing = false;
            }
        })
    }

    toggleUpdateModal(new_state){
        this.setState({
            update_modal: new_state
        }, () => {
            if(this.state.update_modal == true){
                this.startRegisterModalTimeout()

                var dom = null
                if(this.state.register_input.firstname == "") dom = document.querySelector('.update-modal input[id="firstname"]');
                else if(this.state.register_input.lastname == "") dom = document.querySelector('.update-modal input[id="lastname"]');
                else if(this.state.register_input.email == "") dom = document.querySelector('.update-modal input[id="email"]');
                if(dom) dom.click();
                setTimeout(() => {
                    if(dom) dom.click();
                },100)
            }else{
                if(this.register_timeout_function) clearTimeout(this.register_timeout_function);
                this.processing = false;
            }
        })
    }

    toggleDupeEmailModal(new_state){
        this.setState({
            dupe_email_modal: new_state
        }, () => {
            if(this.state.dupe_email_modal == true){
                this.startRegisterModalTimeout()
            }else{
                if(this.register_timeout_function) clearTimeout(this.register_timeout_function);
                this.processing = false;
            }
        })
    }

    startRegisterModalTimeout(){
        if(this.register_timeout_function) clearTimeout(this.register_timeout_function);
        this.register_timeout_function = setTimeout(() => {
            this.setState({
                card_input: '',
                register_input: {email: "", firstname: "", lastname: "", valid: false}
            }, () => {
                let dom = document.getElementsByTagName("BODY")[0];
                if(dom) dom.click();
                this.toggleRegisterModal(false)
            });
        }, 60000);
    }

    toggleWelcomeModal(new_state){
        this.setState({
            welcome_modal: new_state
        }, () => {
            if(this.state.welcome_modal == false) this.processing = false;
        })
    }

    focusInput(name){
        this.setState({
            input_name: name,
        })
    }

    onKeyboardInputChange = (input) => {
        switch(this.state.input_name){
            case "email":
                let new_email = input;
                var curr_input = JSON.parse(JSON.stringify(this.state.register_input));
                curr_input.email = new_email;
                this.setState({
                    register_input: curr_input
                }, () => {
                    this.checkInputValue();

                    this.startRegisterModalTimeout();
                });
                break;

            case "firstname":
                let new_firstname = input;
                var curr_input = JSON.parse(JSON.stringify(this.state.register_input));
                curr_input.firstname = new_firstname;
                this.setState({
                    register_input: curr_input
                }, () => {
                    this.checkInputValue();

                    this.startRegisterModalTimeout();
                });
                break;

            case "lastname":
                let new_lastname = input;
                var curr_input = JSON.parse(JSON.stringify(this.state.register_input));
                curr_input.lastname = new_lastname;
                this.setState({
                    register_input: curr_input
                }, () => {
                    this.checkInputValue();

                    this.startRegisterModalTimeout();
                });
                break;
        }
    }

    clearAllInput = () => {
        this.keyboard.current.clearInput("email");
        this.keyboard.current.clearInput("firstname");
        this.keyboard.current.clearInput("lastname");
    }

    checkInputValue = () => {
        var curr_input = JSON.parse(JSON.stringify(this.state.register_input));

        let new_email = curr_input.email;
        let new_firstname = curr_input.firstname;
        let new_lastname = curr_input.lastname;

        let email_pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        let result = email_pattern.test(new_email);

        if(new_firstname == "" || new_lastname == "") result = false;

        curr_input.valid = result;
        this.setState({
            register_input: curr_input
        });
    }

    onClickCarousel(){
        if(this.state.click == true) return;
        this.setState({
            click: true
        });
    }

    renderCalendarPage(){
        const newPage = document.createElement('div');
        newPage.classList.add('page');
        newPage.innerHTML = `
            <p class="month">${this.monthName}</p>
            <p class="day">${this.dayNum}</p>
            <p class="day-name">${this.dayName}</p>
        `;

        const pages = document.querySelector('.pages');
        pages.appendChild(newPage);

        setTimeout(() => {
            this.updateCalendarPage();
        }, 1600)
    }

    updateCalendarPage(){
        if (this.dayNum < this.daysInMonth(this.month, this.year)) {
            this.dayNum++;
        } else {
            this.dayNum = 1;
        }

        if (this.dayNum === 1 && this.month < 11) {
            this.month++;
        } else if (this.dayNum === 1 && this.month === 11) {
            this.month = 0;
        }

        if (this.dayNum ===1 && this.month === 0) {
            this.year++;
        }
        const newDate = new Date(this.year, this.month, this.dayNum);
        this.dayName = newDate.toLocaleString('en-us', { weekday: 'long' });
        this.monthName = newDate.toLocaleString('en-us', { month: 'long' });

        const page = document.querySelector('.page');
        const pages = document.querySelector('.pages');
        page.classList.add('tear');
        setTimeout(() => {
            pages.removeChild(page);
        }, 800);

        this.renderCalendarPage();
    }

    daysInMonth (month, year) {
        return new Date(year, month + 1, 0).getDate();
    }

    render() {
        if (this.state.redirect) {
            return <Navigate to={this.state.redirect.path} state={this.state.redirect.state} />
        }

        return (
            <div id="home">
                {(this.state.page_ready) ?
                <div>
                    <Modal className="player-error-modal" show={this.state.player_error_modal.show} animation={true} centered>
                        <Modal.Header>
                            <Modal.Title className="w-100 text-center">
                                <div><FontAwesomeIcon icon={faFlag} size="1x" color="#dc3545" /> {this.state.player_error_modal.title}</div>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="pb-0">
                            <div className="container">
                                <div className="row">
                                    <div className="col text-center">
                                        <img src="/assets/images/refree2.png" width={"200px"}/>
                                    </div>
                                    <div className="col text-start d-flex align-items-center">
                                        <div>{this.state.player_error_modal.body}</div>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="container m-0 p-0">
                                <div className="row">
                                    <div className="col d-grid"><Button variant="primary" onClick={e => this.tooglePlayerErrorModal(false, "", "")}>Continue</Button></div>
                                </div>
                            </div>
                        </Modal.Footer>
                    </Modal>
                    <Numpad title="Enter PIN" body="Enter your existing Player’s Club PIN" size="md" show_numpad={this.state.pin_modal} onSubmitInput={(new_input) => this.submitPin(new_input)} type="password"/>
                    <Modal className="welcome-modal" show={this.state.welcome_modal} onHide={() => {this.toggleWelcomeModal(false)}} animation={true} centered>
                        <Modal.Header>
                            <Modal.Title className="w-100">
                                <div className="container">
                                    <div className="row">
                                        <div className="col text-center">{this.state.venue.lobby_message}</div>
                                    </div>
                                </div>
                            </Modal.Title>
                        </Modal.Header>
                        {this.state.player && this.state.player.points_earned && this.state.player.earned_entries && this.state.player.times_played_this_week && this.state.player.points_needed &&
                            <Modal.Body>
                                <div className="container text-center">
                                    <div className="row">
                                        <div className="col">You earned {this.state.player && this.state.player.points_earned} {this.state.venue.name}'s Points</div>
                                    </div>
                                    <div className="row">
                                        <div className="col fs-2 fw-bold">You can play {this.state.player && (this.state.player.earned_entries - this.state.player.times_played_this_week)} {this.state.player && (this.state.player.earned_entries - this.state.player.times_played_this_week) > 1 ? "times" : "time"}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col">For games starting on or after {this.state.eariliest_date && moment(this.state.eariliest_date).format("MMM DD")}</div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col fs-5">Earn {this.state.player && this.state.player.points_needed} points for next free play</div>
                                    </div>
                                    <div className="row">
                                        <div className="col">Play up to 6 tickets each week</div>
                                    </div>
                                </div>
                            </Modal.Body>
                        }
                        <Modal.Footer style={{justifyContent: "center"}}>
                            <div className="fs-5 fw-bold">Tap to Play</div>
                            <div className="carousel-container-popup">
                                {(this.state.promos.length > 0) ?

                                <div className="row d-flex align-items-center justify-content-center">
                                    {this.state.promos.map((item, index) => (
                                        <div key={index} onClick={e => this.playGame(item)} className="text-center" style={{width: "33%"}}>
                                            <img src={item.promo.icon_image_url} style={{width: "90%"}} />
                                        </div>
                                    ))}
                                </div>: null
                                }
                            </div>

                        </Modal.Footer>
                    </Modal>
                    <Modal className="update-modal" show={this.state.update_modal} animation={true} backdrop="static" centered>
                        <Modal.Header>
                            <Modal.Title>
                            <   div className="container">
                                    <div className="row">
                                        <div className="col-auto text-center">
                                            <img src="/assets/images/rename.png" width={"100px"}/>
                                        </div>
                                        <div className="col text-start d-flex align-items-center">
                                            Please add your name to help us reach our winners
                                        </div>
                                    </div>
                                </div>

                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="row">
                                <div className="col">
                                    <Form.Group className="mb-3">
                                        <Form.Label>First Name</Form.Label>
                                        <Form.Control id="firstname" type="text" placeholder="Enter First Name" value={this.state.register_input.firstname} readOnly={true} onClick={e => this.focusInput("firstname")} />
                                    </Form.Group>
                                </div>
                                <div className="col">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Last</Form.Label>
                                        <Form.Control id="lastname" type="text" placeholder="Enter Last Name" value={this.state.register_input.lastname} readOnly={true} onClick={e => this.focusInput("lastname")} />
                                    </Form.Group>
                                </div>
                            </div>
                            <Form>
                                <Form.Group className="mb-3">
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control id="email" type="email" placeholder="Enter email" value={this.state.register_input.email} readOnly={true} onClick={e => this.focusInput("email")} />
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="container m-0 p-0">
                                <div className="row">
                                    <div className="col d-grid">
                                        <Button variant="secondary"
                                            onClick={(e) => {
                                                this.toggleUpdateModal(false)
                                                if(this.state.promos.length == 1){
                                                    this.playGame(this.state.promos[0]);
                                                }else{
                                                    this.setState({
                                                        welcome_modal: true
                                                    })
                                                }
                                            }}
                                        >
                                            Skip
                                        </Button>
                                    </div>
                                    <div className="col d-grid"><Button variant="primary" disabled={!this.state.register_input.valid}
                                        onClick={(e) => this.updatePlayer(e)} //onClick={(e) => this.registerNewPlayer(e)}
                                    >
                                        Update
                                    </Button></div>
                                </div>
                            </div>
                        </Modal.Footer>
                    </Modal>
                    <Modal className="registration-modal" show={this.state.register_modal} animation={true} backdrop="static" centered>
                        <Modal.Header>
                            <Modal.Title>Registration</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="row">
                                <div className="col">
                                    <Form.Group className="mb-3">
                                        <Form.Label>First Name</Form.Label>
                                        <Form.Control id="firstname" type="text" placeholder="Enter First Name" value={this.state.register_input.firstname} readOnly={true} onClick={e => this.focusInput("firstname")} />
                                    </Form.Group>
                                </div>
                                <div className="col">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Last</Form.Label>
                                        <Form.Control id="lastname" type="text" placeholder="Enter Last Name" value={this.state.register_input.lastname} readOnly={true} onClick={e => this.focusInput("lastname")} />
                                    </Form.Group>
                                </div>
                            </div>
                            <Form>
                                <Form.Group className="mb-3">
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control id="email" type="email" placeholder="Enter email" value={this.state.register_input.email} readOnly={true} onClick={e => this.focusInput("email")} />
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="container m-0 p-0">
                                <div className="row">
                                    <div className="col d-grid">
                                        <Button variant="secondary"
                                            onClick={(e) => {
                                                FirebaseService.logEvent("register_card_cancel", {"venue_id": this.state.venue.id, "card_num": this.state.card_input})
                                                this.setState({card_input: '', register_input: {email: "", firstname: "", lastname: "", valid: false}}); this.toggleRegisterModal(false)}
                                            }
                                        >
                                            Close
                                        </Button>
                                    </div>
                                    <div className="col d-grid"><Button variant="primary" disabled={!this.state.register_input.valid}
                                        onClick={(e) => this.checkExistingPlayer(e)}
                                    >
                                        Register
                                    </Button></div>
                                </div>
                            </div>
                        </Modal.Footer>
                    </Modal>
                    <Modal className="home-modal" show={this.state.modal.show} animation={true} centered>
                        <Modal.Body className='text-center' style={{"fontSize": "1.2em"}}>
                            <div className="swipe-card-container">
                                <FontAwesomeIcon className="card-swipe mb-2" icon={faIdCard} size="3x"/>
                                <div className="card-swipe-bar-front"></div>
                                <div className="card-swipe-bar-back"></div>
                                <div className="card-swipe-bar-back-2"></div>
                            </div>
                            <br />
                            {this.state.modal.text}
                        </Modal.Body>
                    </Modal>
                    <Modal className="dupe-email-modal" show={this.state.dupe_email_modal} animation={true} backdrop="static" centered>
                        <Modal.Header>
                            <Modal.Title>Duplicate Email</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="container">
                                <div className="row">
                                    <div className="col text-center d-flex align-items-center">
                                        <img src="/assets/images/refree-yellow-card-2.png" width={"200px"}/>
                                    </div>
                                    <div className="col text-start d-flex align-items-center">
                                        <ul className="text" style={{paddingLeft: "0px"}}>
                                            <li style={{listStyle: "none"}}>
                                                This email has already registered for this promotion.<br /><br />If this was you, please tap <b>Continue</b> to add this card to your account.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="container m-0 p-0">
                                <div className="row">
                                    <div className="col d-grid">
                                        <Button variant="secondary"
                                            onClick={(e) => {
                                                FirebaseService.logEvent("register_card_cancel", {"venue_id": this.state.venue.id, "card_num": this.state.card_input})
                                                this.setState({card_input: '', register_input: {email: "", firstname: "", lastname: "", valid: false}}); this.toggleDupeEmailModal(false)}
                                            }
                                        >
                                            Close
                                        </Button>
                                    </div>
                                    <div className="col d-grid">
                                        <Button variant="primary" onClick={(e) => this.addCardToPlayer(e)}>Continue</Button>
                                    </div>
                                </div>
                            </div>
                        </Modal.Footer>
                    </Modal>
                    <div className="main-carousel carousel-container" style={{position: "fixed", top: "0", width: "100%"}}>
                        {(this.state.promos.length > 1) &&
                        <Carousel
                            infiniteLoop={this.state.promos.length > 3 ? true : false}
                            centerSlidePercentage={this.state.promos.length == 1 ? 100 : this.state.promos.length == 2 ? 50 : 33}
                            autoPlay={this.state.promos.length > 3 ? true : false} interval={4000}
                            showArrows={false} stopOnHover={false} showThumbs={false} showIndicators={false} centerMode={true} dynamicHeight={false}
                            renderArrowPrev={(onClickHandler, hasPrev, label) =>
                                this.state.promos.length > 3  && (
                                    <Button type="button" onClick={onClickHandler} title={label}
                                        style={{ ...arrowStyles, left: 0, padding: "0px 5px 0px 10px" }}
                                    >
                                        <FontAwesomeIcon icon={faCaretLeft} size="3x" />
                                    </Button>
                                )
                            }

                            renderArrowNext={(onClickHandler, hasNext, label) =>
                                this.state.promos.length > 3  && (
                                    <div onClick={e => this.onClickCarousel()}>
                                        <Button type="button" onClick={onClickHandler} title={label}
                                            style={{ ...arrowStyles, right: 0, padding: "0px 10px 0px 5px" }}
                                        >
                                            <FontAwesomeIcon icon={faCaretRight} size="3x" />
                                        </Button>
                                    </div>
                                )
                            }
                        >
                            {this.state.promos.map((item, index) => (
                                <div key={index} className="my-slide">
                                    {/*<Link to="/nfl" state={{ promo: item.promo }}>*/}
                                        <img src={item.promo.icon_image_url} />
                                    {/*</Link>*/}
                                </div>
                            ))}
                        </Carousel>
                        }
                        {(this.state.promos.length == 1) &&
                            <div className="home-gradient-border">
                                <div className="home-frame">
                                    {/*
                                    <div className="home-quadrado">
                                        <img src={this.state.promos[0].promo.icon_image_url} className="home-logo shadowfilter"/>
                                        <img src="./assets/images/graphic-banner-1.png"className="home-banner"/>
                                    </div>
                                    <div className="home-calendar">
                                        <div className="calendar">
                                            <div className="pages"></div>
                                        </div>
                                    </div>
                                    <div>
                                        <img src="./assets/images/graphic-insert-card.png" width="50%" className="home-insert-card"/>
                                        <img src="./assets/images/graphic-arrow-1.png" width="12%" className="home-arrow"/>
                                    </div>
                                    */}
                                    <div className="home-background">
                                        {this.state.promos.map((item, index) => (
                                            <div key={index} className="my-slide">
                                                {/*<Link to="/nfl" state={{ promo: item.promo }}>*/}
                                                    <img src={item.promo.icon_image_url} />
                                                {/*</Link>*/}
                                            </div>
                                        ))}

                                        {this.state.vid != "" &&
                                            <video className="home-video" playsInline autoPlay muted loop
                                                src={this.state.vid} type="video/mp4"
                                            ></video>
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <VirtualKeyboard
                        ref={r => (this.keyboard.current = r)}
                        inputName={this.state.input_name}
                        onChange={this.onKeyboardInputChange}
                    />
                    {this.state.vid != "" &&
                        <video className="home-video-2" playsInline autoPlay muted loop
                            src={this.state.vid} type="video/mp4"
                        ></video>
                    }
                </div>: null
                }
            </div>
        );
    }
}

export default withRouter(Home);
