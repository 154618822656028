import React, { Component } from "react";
import { Modal, Form, Button }  from 'react-bootstrap';

class Numpad extends Component {
    constructor(props) {
        super(props);

        this.state = {
            title: this.props.title,
            show: false,
            input: '',
            body: this.props.body ? this.props.body : "",
            image: this.props.image ? this.props.image : "",
        };
    }

    componentDidMount(){
        this.setState({
            show: false,
            input: ''
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot){
        if (this.props.show_numpad !== prevState.show) {
            this.setState({
                show: this.props.show_numpad,
                body: this.props.body ? this.props.body : "",
                image: this.props.image ? this.props.image : "",
                input: this.props.show_numpad ? "" : this.state.input
            })
        }
    }

    onInput(value){
        this.setState({
            input: this.state.input + "" + value
        })
    }

    onBackspace(){
        if(this.state.input.length == 0) this.toggleModal(false)

        let new_input = this.state.input;
        if(new_input.length > 1) new_input = new_input.slice(0, -1)
        else new_input = "";

        this.setState({
            input: new_input
        })
    }

    submitInput(){
        return this.props.onSubmitInput(this.state.input);
    }

    toggleModal(new_state){
        this.setState({
            show: new_state,
            input: !new_state ? "" : this.state.input
        })
    }

    render() {
        return (
            <Modal className="numpad numpad-modal" show={this.state.show} animation={true} backdrop="static" keyboard={false} centered size={this.props.size ? this.props.size : "sm"}>
                <Modal.Header className="pb-0">
                    <Modal.Title className="w-100">
                        <div className="container">
                            <div className="row">
                                <div className="col text-center pb-1">{this.state.title}</div>
                            </div>
                            <div className="row d-flex align-items-center justify-content-center">
                                {this.state.image && <div className="col"><img src={this.state.image} width="100%" /></div>}
                                {this.state.body && <div className={"fs-6 col " + (this.state.image ? "" : "text-center") }>{this.state.body}</div>}
                            </div>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <div className="row">
                            <div className="col pb-1 px-0">
                                <Form.Control type={this.props.type ? this.props.type : "text"} name="pin" className="text-center" value={this.state.input} readOnly/>
                            </div>
                        </div>
                        <div className="row pb-1">
                            <div className="col text-center key d-grid px-0"><Button variant="light" onClick={() => {this.onInput("7")}}>7</Button></div>
                            <div className="col text-center key d-grid px-1"><Button variant="light" onClick={() => {this.onInput("8")}}>8</Button></div>
                            <div className="col text-center key d-grid px-0"><Button variant="light" onClick={() => {this.onInput("9")}}>9</Button></div>
                        </div>
                        <div className="row pb-1">
                            <div className="col text-center key d-grid px-0"><Button variant="light" onClick={() => {this.onInput("4")}}>4</Button></div>
                            <div className="col text-center key d-grid px-1"><Button variant="light" onClick={() => {this.onInput("5")}}>5</Button></div>
                            <div className="col text-center key d-grid px-0"><Button variant="light" onClick={() => {this.onInput("6")}}>6</Button></div>
                        </div>
                        <div className="row pb-1">
                            <div className="col text-center key d-grid px-0"><Button variant="light" onClick={() => {this.onInput("1")}}>1</Button></div>
                            <div className="col text-center key d-grid px-1"><Button variant="light" onClick={() => {this.onInput("2")}}>2</Button></div>
                            <div className="col text-center key d-grid px-0"><Button variant="light" onClick={() => {this.onInput("3")}}>3</Button></div>
                        </div>
                        <div className="row">
                            <div className="col text-center key d-grid px-0">
                                {this.state.input.length == 0 &&
                                    <Button variant="danger" onClick={() => {this.onBackspace()}}>{"Close"}</Button>
                                }
                                {this.state.input.length != 0 &&
                                    <Button variant="light" onClick={() => {this.onBackspace()}}>{"<"}</Button>
                                 }
                            </div>
                            <div className="col text-center key d-grid px-1"><Button variant="light" onClick={() => {this.onInput("0")}}>0</Button></div>
                            <div className="col text-center key d-grid px-0"><Button variant="success" onClick={() => {this.submitInput()}} disabled={this.state.input.length > 0 ? false : true}>{"Enter >"}</Button></div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}
export default Numpad;