import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import { Button }  from 'react-bootstrap';

import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons'

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import VirtualKeyboard from "../../components/VirtualKeyboard";

import { withRouter } from '../../with-router';
import AuthService from "../../services/auth";
import GameService from "../../services/game";

import './Home.css';

const arrowStyles = {
    position: 'absolute',
    zIndex: 2,
    top: "0",
    height: "100%",
    borderRadius: "0",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    border: "0px"
};

class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page_ready: false,
            redirect: null,
            venue: null,
            promos: [],
            player: null,
            click: false,
        };
    }
  
    componentDidMount() {
        this.props.showModal("Loading...")
        let local_bg = localStorage.getItem("home_bg");
        if(local_bg != null) this.backgroundImage(local_bg);

        let player = localStorage.getItem("demo_user");
        if(player == null || player == ""){
            this.setState({ redirect: { path:"/guest/login", state: null } }, () => {
                this.props.hideModal();
            }); 
        }
        
        GameService.getVenueAndGames().then((data) => {

            let eariliest = null;
            data.promos.forEach((a, index) => {
                if(a.promoperiods.length){
                    a.promoperiods.forEach(b => {
                        if(eariliest == null) eariliest = moment(b.event_start_time);
                        else{
                            let isBefore = moment(b.event_start_time).isBefore(eariliest);
                            if(isBefore) eariliest = moment(b.event_start_time);
                        }
                    })
                }else{
                    data.promos.splice(index, 1);
                }
            })

            var new_promos = data.promos.filter(a => {
                return moment().isBetween(moment(a.promo.start_time), moment(a.promo.end_time)) && a.promo.status == 1
            });

            this.setState({
                page_ready: true,
                venue: data.venue,
                promos: new_promos,
                player: JSON.parse(player)
            }, () => {
                if(this.state.venue.lobby_background_image_url) this.backgroundImage(this.state.venue.lobby_background_image_url);
                else this.backgroundImage("");

                this.props.hideModal();
            })
        }).catch(error => {
            console.log(error)
            if(error.response.status == 401){
                AuthService.refreshAuth().then((data) => {
                    window.location.reload(false);
                }).catch(error => {
                    AuthService.logout();
                    this.setState({ redirect: { path:"/guest/login", state: null } }, () => {
                        this.props.hideModal();
                    }); 
                });
            }
        });
    }

    backgroundImage(bg){
        let html = document.getElementsByTagName("html");
        if(html.length > 0){
            if(bg) html[0].style.backgroundImage = "url('" + bg + "')";
            else html[0].style.backgroundImage = "";
            
            localStorage.setItem("home_bg", bg);
        }
    }

    redirectToGame(path, promos){
        let venue = this.state.venue;
        let promo = promos.promo;
        this.setState({ 
            redirect: {
                path: "/" + path,
                state: {
                    venue: venue,
                    promo: promo,
                    player: this.state.player,
                    demo: true
                } 
            }
        });
    }
    
    welcomeModalCarouselClickEvent(item){
        let url = "";

        switch(item.promo.game_type){
            case 0:
                url = "head_to_head";
                break;
            case 1:
                url = "placement";
                break;
            case 2:
                url = "bracket";
                break;
            case 3:
                url = "pick_the_props";
                break;
            default:

        }
        if(url == "") return;
        this.redirectToGame(url, item);
    }

    onClickItem(item){
        this.welcomeModalCarouselClickEvent(item);
    }

    onClickCarousel(){
        if(this.state.click == true) return;
        this.setState({
            click: true
        });
    }

    render() {
        if (this.state.redirect) {
            return <Navigate to={this.state.redirect.path} state={this.state.redirect.state} />
        }
        
        return (
            <div id="home">
                {(this.state.page_ready) ?
                <div>
                    <div className="main-carousel carousel-container" style={{position: "fixed", top: "0", width: "100%"}}>
                        {(this.state.promos.length > 0) ?
                        <Carousel 
                            infiniteLoop={this.state.promos.length > 3 ? true : false} 
                            centerSlidePercentage={this.state.promos.length == 1 ? 100 : this.state.promos.length == 2 ? 50 : 33}
                            autoPlay={this.state.promos.length > 3 ? true : false} interval={4000}
                            showArrows={true} stopOnHover={false} showThumbs={false} showIndicators={false} centerMode={true} dynamicHeight={false}
                            renderArrowPrev={(onClickHandler, hasPrev, label) =>
                                this.state.promos.length > 3  && (
                                    <Button type="button" onClick={onClickHandler} title={label}
                                        style={{ ...arrowStyles, left: 0, padding: "0px 5px 0px 10px" }}
                                    >
                                        <FontAwesomeIcon icon={faCaretLeft} size="3x" />
                                    </Button>
                                )
                            }

                            renderArrowNext={(onClickHandler, hasNext, label) =>
                                this.state.promos.length > 3  && (
                                    <div onClick={e => this.onClickCarousel()}>
                                        <Button type="button" onClick={onClickHandler} title={label}
                                            style={{ ...arrowStyles, right: 0, padding: "0px 10px 0px 5px" }}
                                        >
                                            <FontAwesomeIcon icon={faCaretRight} size="3x" />
                                        </Button>
                                    </div>
                                )
                            }
                        >
                            {this.state.promos.map((item, index) => (
                                <div key={index} className="my-slide" onClick={e => this.onClickItem(item)}>
                                    {/*<Link to="/nfl" state={{ promo: item.promo }}>*/}
                                        <img src={item.promo.icon_image_url}/>
                                    {/*</Link>*/}
                                </div>
                            ))}
                        </Carousel> : null
                        }
                    </div>
                    <VirtualKeyboard 
                        inputName={"email"}
                        onChange={this.onKeyboardInputChange}
                    />
                </div>: null
                }
            </div>
        );
    }
}

export default withRouter(Home);