import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import { Modal, Button }  from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFlag } from '@fortawesome/free-solid-svg-icons'

import FirebaseService from "../services/firebase";

class GameTimeout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: null,
            show: false,
            timeout: this.props.timeout ? this.props.timeout : 15000
        }

        this.timeout_function = null;
        this.modal_timeout_function = null;
    }

    componentDidMount(){
        this.startTimeout();
        this.registerOnClick();
    }

    componentDidUpdate(prevProps, prevState, snapshot){
        if (this.props.timeout !== prevState.timeout) {
            this.setState({
                timeout: this.props.timeout
            }, () => {
                this.startTimeout();
            })
        }
    }

    componentWillUnmount(){
        if(this.timeout_function) clearTimeout(this.timeout_function);
        if(this.modal_timeout_function) clearTimeout(this.modal_timeout_function);
    }

    startTimeout(){
        if(this.state.show) {
            this.startModalTimeout();
            return
        };
        
        if(this.timeout_function) clearTimeout(this.timeout_function);
        this.timeout_function = setTimeout(() => {
            this.setState({
                show: true
            }, () => {
                FirebaseService.logEvent("game_timeout", {"venue_id": this.props.state.venue.id, "card_num": this.props.state.player.card_number, "promo_id": this.props.state.promo.id, "timeout": this.state.timeout})
                this.props.onTimeout();
                this.startModalTimeout();
            })
        }, this.state.timeout)
    }

    registerOnClick(){
        document.addEventListener("click", (e) => {
            this.startTimeout();
        });
        
        document.addEventListener('touchstart', (e) => {
            this.startTimeout();
        })

        document.addEventListener('touchmove', (e) => {
            this.startTimeout();
        })
        
        document.addEventListener('touchend', (e) => {
            this.startTimeout();
        })

        let scroll_container = document.querySelector('.scroll-container');
        if(scroll_container){
            scroll_container.addEventListener('scroll', (e) => {
                this.startTimeout();
            })
        }

        let user_picks_scroll_container = document.querySelector('.user-picks-scroll-container');
        if(user_picks_scroll_container){
            user_picks_scroll_container.addEventListener('scroll', (e) => {
                this.startTimeout();
            })
        }
    }

    startModalTimeout(){
        if(this.modal_timeout_function) clearTimeout(this.modal_timeout_function);
        this.modal_timeout_function = setTimeout(() => {
            FirebaseService.logEvent("game_timeout_exit", {"venue_id": this.props.state.venue.id, "card_num": this.props.state.player.card_number, "promo_id": this.props.state.promo.id, "timeout": this.state.timeout/2, "manual_press": false})
            this.goHome();
        }, this.state.timeout/2)
    }

    hideModal(){
        this.setState({
            show: false
        }, () => {
            FirebaseService.logEvent("game_timeout_clear", {"venue_id": this.props.state.venue.id, "card_num": this.props.state.player.card_number, "promo_id": this.props.state.promo.id})
            if(this.modal_timeout_function) clearTimeout(this.modal_timeout_function);
            this.startTimeout();
        })
    }

    exit(){
        FirebaseService.logEvent("game_timeout_exit", {"venue_id": this.props.state.venue.id, "card_num": this.props.state.player.card_number, "promo_id": this.props.state.promo.id, "timeout": this.state.timeout/2, "manual_press": true,})
        this.goHome();
    }

    goHome(){
        this.setState({ redirect: { path: localStorage.getItem("demo_user") ? "/guest/home" : '/home', state: null } });
    }

    render() {
        if (this.state.redirect) {
            return <Navigate to={this.state.redirect.path} state={this.state.redirec} />
        }
        return (
            <Modal className="timeout-modal" show={this.state.show} animation={true} centered>
                <Modal.Header>
                    <Modal.Title className="w-100 text-center">
                        <div><FontAwesomeIcon icon={faFlag} size="1x" color="#ffc107" /> Session Timeout</div>    
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="pb-0">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <img src="/assets/images/refree-yellow-card.png" width={"200px"}/>
                            </div>
                            <div className="col text-start" style={{display: "grid", justifyContent: "center"}}>
                                <div className="pb-2">Are you still playing?</div>
                                <div className="pb-2">Press "Continue" to continue your session.</div>
                                <div>Press "Exit Game" to return to the Lobby</div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="container m-0 p-0">
                        <div className="row">
                            <div className="col d-grid"><Button variant="danger" onClick={e => this.exit()}>Exit Game</Button></div>
                            <div className="col d-grid"><Button variant="primary" onClick={e => this.hideModal()}>Continue</Button></div>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        )
    }
}
export default GameTimeout;