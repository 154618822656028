import React, { Component } from "react";
import { Navigate } from "react-router-dom";

import { withRouter } from '../with-router';
import AuthService from "../services/auth";

class Logout extends Component {
    constructor(props) {
        super(props);

        this.state = {
            redirect: null,
        }
    }

    componentDidMount(){
        localStorage.removeItem("user");
        localStorage.removeItem("demo_user");
        AuthService.logout();
        this.setState({ redirect: { path:"/login", state: null } }); 
    }

    render() {
        if (this.state.redirect) {
            return <Navigate to={this.state.redirect.path} state={this.state.redirect.state} />
        }

        return (
            <div>Logging out...</div>
        );
    }
}

export default withRouter(Logout);