import React, { Component } from "react";
import { Modal, Button }  from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFlag } from '@fortawesome/free-solid-svg-icons'

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            hasError: false, 
            error: null 
        };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, error: error.message};
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        console.log(error, errorInfo);
    }

    goHome(){
        window.location = localStorage.getItem("demo_user") ? "/guest/home" : '/home';
    }

    render() {
        if (this.state.hasError) {
        // You can render any custom fallback UI
            return (
                <Modal className="error-modal" show={true} animation={true} centered>
                    <Modal.Header>
                        <Modal.Title className="w-100">
                            <div className="container">
                                <div className="row">
                                    <div className="col text-center"><FontAwesomeIcon icon={faFlag} size="1x" color="#dc3545" /> Something Went Wrong!</div>
                                </div>
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="pb-0">
                        <div className="container">
                            <div className="row">
                                <div className="col text-center">
                                    <img src="/assets/images/refree2.png" width={"200px"}/>
                                </div>
                                <div className="col text-start d-flex align-items-center">
                                    <ul className="text">
                                        <li>
                                            Something went wrong and we couldn't complete your request
                                        </li>
                                        {this.state.error && <li className="p-2" style={{backgroundColor: "lightgray", fontSize: "0.8rem"}}>
                                            {this.state.error}
                                        </li>}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer style={{justifyContent: "center"}}>
                        <div className="container m-0 p-0">
                            <div className="row">
                                <div className="col d-grid">
                                    <Button variant="primary" onClick={(e) => this.goHome() }>Return to Lobby</Button>
                                 </div>
                            </div>
                        </div>
                    </Modal.Footer>
                </Modal>
            )
        }
        return this.props.children; 
    }
}

export default ErrorBoundary;