import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL + "/api";

class AuthService {
    login(username, password) {
        var config = {
            method: 'post',
            url: API_URL + "/token/",
            headers: { 
                'Content-Type': 'application/json'
            },
            data : JSON.stringify({
                "username": username,
                "password": password
            })
        };
        return axios(config).then(response => {
            //console.log(response);
            if (response.data.access) {
                localStorage.setItem("auth", JSON.stringify(response.data));
            }
            return response.data;
        });
    }

    logout() {
        localStorage.removeItem("auth");
    }

    getCurrentAuth() {
        return JSON.parse(localStorage.getItem('auth'));;
    }

    refreshAuth(){
        var auth = this.getCurrentAuth();
        if(auth == null) return Promise((resolve, reject) => {
            reject(null);
        });

        var config = {
            method: 'post',
            url: API_URL + "/token/refresh/",
            headers: { 
                'Content-Type': 'application/json'
            },
            data : JSON.stringify({
                "refresh": auth.refresh,
            })
        };
        return axios(config).then(response => {
            //console.log(response);
            if (response.data.access) {
                var new_auth = auth;
                new_auth.access = response.data.access
                localStorage.setItem("auth", JSON.stringify(new_auth));
            }
            return response.data;
        });
    }
}

export default new AuthService();