import React from 'react';
import { Navigate, Outlet } from 'react-router-dom'

import AuthService from "../services/auth";

const useAuth=()=>{
    const auth = AuthService.getCurrentAuth()
    if(auth){
        return true
    } else {
        return false
    }
}

const  PrivateRoute=(props) => {
    const auth = useAuth()
    return auth ? <Outlet/> : <Navigate to="/login"/>
}

export default PrivateRoute;