import React, { Component } from "react";

import Keyboard from "react-simple-keyboard";
import 'react-simple-keyboard/build/css/index.css';

class VirtualKeyboard extends Component {
    constructor(props) {
        super(props);
        this.keyboard = React.createRef();
    }

    componentDidMount(){
        this.registerOnClick();
    }

    clearInput(inputName){
        this.keyboard.current.clearInput(inputName);
    }

    setInput(input, inputName){
        this.keyboard.current.setInput(input, inputName);
    }

    registerOnClick(){
        document.addEventListener("click", (e) => {
            //console.log(e.target);
            if(e.target.tagName == "INPUT"){
                if(e.target && e.target.closest('.numpad-modal') == null){
                    this.viewKeyboard();
                }
            }else{
                if(e.target && e.target.closest('.virtual-keyboard-appear') == null){
                    this.hideKeyboard();
                }
            }
        });
    }

    viewKeyboard(){
        let keyboard = document.querySelector(`.virtual-keyboard`);
        if(keyboard){
            keyboard.classList.remove('virtual-keyboard')
            keyboard.classList.add('virtual-keyboard-appear')
        }
    }

    hideKeyboard(){
        let keyboard = document.querySelector(`.virtual-keyboard-appear`);
        if(keyboard){
            keyboard.classList.remove('virtual-keyboard-appear')
            keyboard.classList.add('virtual-keyboard')
        }
    }

    onKeyPress = (button) => {
        //console.log("Button pressed", button);
        if (button === "{shift}" || button === "{lock}") this.handleShift();
    }

    handleShift(event) {
        let currentLayout = this.keyboard.current.options.layoutName;
        let shiftToggle = currentLayout === "default" ? "shift" : "default";
      
        this.keyboard.current.setOptions({
            layoutName: shiftToggle,
        });
        this.viewKeyboard()
    }

    render() {
        return (
            <Keyboard
                baseClass="virtual-keyboard"
                layout={{
                        'default': [
                            '` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
                            '{lock} q w e r t y u i o p [ ] \\',
                            'a s d f g h j k l ; \'',
                            'z x c v b n m , . /',
                            '.com @ {space}'
                        ],
                        'shift': [
                            '~ ! @ # $ % ^ &amp; * ( ) _ + {bksp}',
                            '{lock} Q W E R T Y U I O P { } |',
                            'A S D F G H J K L : "',
                            'Z X C V B N M &lt; &gt; ?',
                            '.com @ {space}'
                        ]
                    }
                }
                keyboardRef={r => (this.keyboard.current = r)}
                inputName={this.props.inputName}
                onChange={this.props.onChange}
                onKeyPress={this.onKeyPress}
            />
        )
    }
}
export default VirtualKeyboard;